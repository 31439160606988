<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="120px"
        >
          <el-form-item label="任务名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入任务名称关键字"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="任务分类" prop="key">
            <el-select v-model="queryParams.category" placeholder="请选择">
              <el-option
                v-for="dict in categoryOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="待办人userId" prop="assignee">
            <el-input
              v-model="queryParams.assignee"
              placeholder="待办人userId"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="企业标识" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <!-- <el-col :span="1.5">
              <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                v-preventReClick
                @click="handleAdd"
                >新增</el-button
              >
            </el-col> -->
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="taskList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="任务ID"
            align="center"
            key="taskId"
            prop="taskId"
            width="120"
            fixed="left"
          />
          <el-table-column
            label="任务名称"
            align="center"
            key="taskSubject"
            prop="taskSubject"
            :show-overflow-tooltip="true"
            width="260"
            fixed="left"
          />
          <el-table-column
            label="流程定义ID"
            align="center"
            key="processDefineId"
            prop="processDefineId"
            width="120"
          />
          <el-table-column
            label="流程实例ID"
            align="center"
            key="processInstanceId"
            prop="processInstanceId"
            width="120"
          />
          <el-table-column
            label="businessKey"
            align="center"
            key="businessKey"
            prop="businessKey"
            width="120"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="任务分类" align="center" prop="category">
            <template slot-scope="scope">
              <span>{{
                $selectDictLabel(categoryOptions, scope.row.category)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="企业标识"
            align="center"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="当前待办人"
            align="center"
            key="assignee"
            prop="assignee"
            width="280"
          >
            <template slot-scope="scope">{{
              scope.row.assigneeUser.userName
            }}</template>
          </el-table-column>
          <el-table-column
            label="起草人"
            align="center"
            key="startUserId"
            prop="startUserId"
            width="280"
          >
            <template slot-scope="scope">{{
              scope.row.startUser.userName
            }}</template>
          </el-table-column>
          <el-table-column
            label="起草时间"
            align="center"
            prop="startTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.startTime) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
              label="完成时间"
              align="center"
              prop="endTime"
              width="160"
            >
              <template slot-scope="scope">
                <span>{{ $parseTime(scope.row.endTime) }}</span>
              </template>
            </el-table-column> -->
          <el-table-column
            label="操作"
            align="center"
            width="180"
            class-name="small-padding fixed-width"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="viewTask(scope.row)"
                >查看</el-button
              >
              <!-- <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-view"
                  v-preventReClick
                  @click="viewWf(scope.row)"
                  >查看</el-button
                > -->
              <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="transferTask(scope.row)"
                >转移</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="deleTask(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-dialog
      title="流程图"
      :visible.sync="openLct"
      width="50%"
      append-to-body
    >
      <el-image :src="lctUrl"></el-image>
    </el-dialog>
    <el-dialog
      title="转移任务"
      :visible.sync="openTransferDialog"
      width="500px"
      append-to-body
    >
      <el-form
        ref="transferForm"
        :model="transferForm"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="任务标题" prop="taskSubject">
          <el-input
            v-model="transferForm.taskSubject"
            disabled
            clearable
            size="small"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="任务拥有人" prop="ownerId">
          <el-input
            v-model="transferForm.ownerId"
            disabled
            clearable
            size="small"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="转办人" prop="assignee">
          <el-input
            v-model="transferForm.assignee"
            placeholder="请输入转办人userId"
            clearable
            size="small"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="转办意见" prop="comment">
          <el-input
            v-model="transferForm.comment"
            placeholder="请输入转办意见"
            clearable
            type="textarea"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            v-preventReClick
            @click="submitTransferForm()"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="流程列表"
      :visible.sync="openWf"
      width="50%"
      append-to-body
    >
      <el-table v-loading="wfLoading" :data="processWfList">
        <el-row>
          <el-table-column label="审批意见" align="center" style="float: left">
            <template slot-scope="scope">
              <div
                v-for="item in scope.row.userTaskList"
                :key="item.userId"
                style="width: 100%; float: left; margin: 0 0 5px 0"
              >
                <div
                  style="
                    float: left;
                    width: 30%;
                    text-align: right;
                    padding: 5px 10px 0px 0px;
                  "
                >
                  {{ item.user.userName }}
                </div>
                <div style="float: left; width: 70%; text-align: center">
                  <div
                    v-for="v in item.userCommentList"
                    :key="v.id"
                    style="
                      float: left;
                      width: 100%;
                      background-color: #f8f8f8;
                      padding: 5px 10px 5px 10px;
                    "
                  >
                    <div style="float: left; width: 100%">
                      <div style="float: left">
                        意见：{{ getComment(v.comment) }}
                      </div>
                      <div style="float: right">
                        {{ $parseTime(v.datetime) }}
                      </div>
                    </div>
                    <div style="float: left">
                      评论：{{ getCommentText(v.comment) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="审批节点"
            align="center"
            prop="nodeName"
            width="120"
          />
        </el-row>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="openWfUrl" width="70%" append-to-body>
      <iframe :src="taskUrl" frameborder="0" class="iframe"></iframe>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  deleTask,
  viewTask,
  getUncompleteTaskList,
  transferTask,
  detailWorkFlow
} from '@/api/system/activiti'
import { getMenuPriByMenuId } from '@/api/menu/index'
import config from '@/components/Config'
import { TOKEN_KEY } from '@/utils/constant'
import { ACTIVITI } from '@/utils/activitiConstant'
export default {
  name: 'app',
  data() {
    return {
      openWfUrl: false,
      taskSubject: undefined,
      taskUrl: undefined,
      apiUrl: config.apiUrl, // 后台部署的api服务
      token: sessionStorage.getItem(TOKEN_KEY),
      workFlowTitle: '',
      deployListTitle: '',
      menuPriPath: [],
      modelRow: {},
      wfLoading: false,
      processWfList: [],
      openWf: false,
      lctUrl: '',
      openLct: false,
      keyDisabled: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      showSearchDeploy: true,
      // 总条数
      total: 0,
      deployTotal: 0,
      // 表格数据
      processDefineList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      openEditor: false,
      openDeployList: false,
      openTransferDialog: false,
      deployLoading: false,
      deployModelList: [],
      editorSrc: '',
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      categoryOptions: ACTIVITI.CATEGORY,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        key: undefined,
        assignee: undefined,
        category: undefined,
        enterpriseSecret: undefined
      },
      transferForm: {},
      taskList: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        assignee: [
          { required: true, message: '转办人不能为空', trigger: 'blur' }
        ],
        comment: [
          { required: true, message: '转办意见不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {},
  created() {
    this.getList()
    this.getRolePriList()
  },
  methods: {
    getComment(comment) {
      return comment.split('&')[0]
    },
    getCommentText(comment) {
      return comment.split('&')[1]
    },
    viewWf(row) {
      debugger
      let data = {
        businessKey: row.businessKey
      }
      this.wfLoading = true
      detailWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.processWfList = res.data.result.sidList
          this.openWf = true
          this.wfLoading = false
        } else {
          this.$msgError(res.data.message)
          this.wfLoading = false
        }
      })
    },
    transferTask(row) {
      this.openTransferDialog = true
      this.transferForm.taskSubject = row.taskSubject
      this.transferForm.ownerId = row.assignee
      this.transferForm.businessKey = row.businessKey
    },
    submitTransferForm() {
      debugger
      this.$refs['transferForm'].validate((valid) => {
        if (valid) {
          let data = {
            businessKey: this.transferForm.businessKey,
            userId: this.transferForm.ownerId,
            toUserId: this.transferForm.assignee,
            comment: this.transferForm.comment
          }
          transferTask(data).then((res) => {
            if (res.data.success) {
              this.$msgSuccess('转移成功')
              this.openTransferDialog = false
            } else {
              this.$msgError(res.data.message)
            }
          })
        }
      })
    },
    /** 查询角色列表 */
    getRolePriList() {
      // debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      getUncompleteTaskList(JSON.stringify(this.queryParams)).then(
        (response) => {
          if (response.data.success) {
            this.taskList = response.data.result.records
            this.total = response.data.result.total
            this.loading = false
          } else {
            this.$msgError(response.data.message)
            this.loading = false
          }
        }
      )
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        key: undefined,
        enterpriseSecret: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    viewTask(row) {
      this.taskUrl = row.taskUrl
      this.openWfUrl = true
      //var data={
      //  businessKey: row.businessKey
      //}

      //viewTask(data).then(res=>{
      //      debugger
      //      let url = window.URL.createObjectURL(new Blob([res.data]));
      //      this.lctUrl=url;
      //      this.openLct=true;
      //
      //});
    },
    deleTask(row) {
      debugger
      this.$confirm(
        '是否确认删除名称为"' + row.taskSubject + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        let data = {
          businessKey: row.businessKey
        }
        this.loading = true
        deleTask(data).then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
            this.loading = false
          }
        })
      })
    }
  }
}
</script>
  <style>
.iframe {
  width: 100%;
  height: calc(100vh - 85px);
}
/* .el-dialog__close{
      display: none;
    }
    .el-icon-close{
      display: none;
    }
   .el-dialog__body{
    padding:0px 0 0 0;
   } */
</style>
  