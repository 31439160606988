<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="68px"
        >
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="queryParams.title"
              placeholder="请输入标题关键词"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="内容分类" prop="category" >
              <el-select v-model="queryParams.category" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.guid"
                  :label="dict.categoryName"
                  :value="dict.guid"
                ></el-option>
              </el-select>
            </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="dataList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            align="center"
            key="guid"
            prop="guid"
            width="280"
          />
          <el-table-column
            label="标题"
            align="center"
            key="title"
            prop="title"
            :show-overflow-tooltip="true"
          />
          <!-- <el-table-column
            label="创建人"
            align="center"
            key="creatorName"
            prop="creatorName"
            :show-overflow-tooltip="true"
          /> -->

          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="1000px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        
         <el-row>
          <el-col >
            <el-form-item label="分类" prop="category" >
              <el-select v-model="form.categoryGuid" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.guid"
                  :label="dict.categoryName"
                  :value="dict.guid"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="标题" prop="title">
              <el-input  style="width: 800px" v-model="form.title" placeholder="请输入标题"   />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="副标题" prop="title">
              <el-input style="width: 800px" v-model="form.subtitle" type="textarea" placeholder="请输入副标题"   />
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col >
            <el-form-item label="内容" prop="content" >
            <tinymce v-model="form.content" placeholder="请输入内容简介" style="width: 800px;margin-bottom: 20px;"></tinymce> 
             
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listKupinCategory
} from '@/api/kupin/category'
import tinymce from '@/components/mce/tinymce.vue';
import {
  listKupin,
  getKupin,
  addKupin,
  updateKupin,
  delKupin
} from '@/api/kupin/content'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'kupin',
   components: {
    'tinymce': tinymce
  },
  data() {
    return {
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      //分类列表
      categoryOptions:[],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        title: undefined,
        category: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        title: [
          { required: true, message: '系统名称不能为空', trigger: 'blur' }
        ],
        categoryGuid: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ]
        
      }
    }
  },
  created() {
    this.getRolePriList();
    this.getCategoryList();
    this.getList();
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取分类
    getCategoryList(){
        var data = {};
        listKupinCategory(data).then((res) => {
            if (res.data.success) {
               this.categoryOptions=res.data.result.records;
            } else {
                this.$msgError(res.data.message)
            }
        })
    },
    getList() {
      this.loading = true
      listKupin(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        orderNum: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      // this.reset()
      // this.open = true
      // this.title = '添加内容'
      this.$router.push('/system/kupin/edit');
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/system/kupin/edit?guid='+row.guid);
      // this.reset()
      // var data = {
      //   guid: row.guid
      // }
      // getKupin(data).then((res) => {
      //   if (res.data.success) {
      //     this.form = res.data.result
      //     this.open = true
      //     this.title = '修改内容'
      //   } else {
      //     this.$msgError(res.data.message)
      //   }
      // })
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.guid != undefined) {
            updateKupin(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addKupin(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除系统名称为"' + row.title + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delKupin(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
  }
}
</script>
