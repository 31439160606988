import {request,post} from '@/plugins/request';

  // 新增
  export function addRole(data) {
    return post('/rbac/v0.1/rbac/role/role/create',data);
  }
  // 修改
  export function updateRole(data) {
    return post('/rbac/v0.1/rbac/role/role/update',data);
  }
  // 删除
  export function delRole(data) {
    return post('/rbac/v0.1/rbac/role/role/delete',data);
  }
  // 详情
  export function getRole(data) {
    return post('/rbac/v0.1/rbac/role/role/detail',data);
  }
  // 列表
  export function listRole(data) {
    return post('/rbac/v0.1/rbac/role/role/list',data);
  }
  //获取角色数据权限
  export function getRoleDataPri(data){
    return post('/rbac/v0.1/rbac/role/rdp/list',data);
  }
  //批量创建角色数据权限
  export function batchCreateRoleDataPri(data){
    return post('/rbac/v0.1/rbac/role/rdp/batch/create',data);
  }