import {post,download,uploadPic} from '@/plugins/request';

// 查询分页列表
export function listApp(data) {
    return post('/generator/v0.1/generator/application/app/list',data);
  }
  
  // 查询详细
  export function getApp(data) {
    return post('/generator/v0.1/generator/application/app/detail',data);
  }
  
  // 新增
  export function addApp(data) {
    return post('/generator/v0.1/generator/application/app/create',data);
  }
  
  // 修改
  export function updateApp(data) {
    return post('/generator/v0.1/generator/application/app/update',data);
  }
  
  
  
  // 删除
  export function delApp(data) {
    return post('/generator/v0.1/generator/application/app/delete',data);
  }

  //下载
  export function generateApp(data){
   return download('/generator/v0.1/generator/application/app/generate',data);
  }
  
  //生成Key
 export function generatePairKey(data){
  return post('/generator/v0.1/generator/tools/aes/generate',data);
 } 
  //下载Key
  export function downloadPairKey(data){
    return download('/generator/v0.1/generator/tools/aes/download',data);
  } 
 //加密Key
 export function encrypt(data){
  return post('/generator/v0.1/generator/tools/aes/encrypt',data);
 } 
 //解密Key
 export function decrypt(data){
  return post('/generator/v0.1/generator/tools/aes/decrypt',data);
 } 
 //加密用户密码
 export function encryptPwd(data){
  return post('/generator/v0.1/generator/tools/pwd/encrypt',data);
 } 