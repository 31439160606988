<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
.el-main {
  padding: 0 !important;
}

.app-container {
  padding: 20px;
}

.el-image-viewer__prev,
.el-image-viewer__next {
  display: none;
}

pre {
  background: #000;
  display: block;
  overflow: auto;
  color: #fff;
}
</style>
