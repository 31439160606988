<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="90px"
        >
          <el-form-item label="客户名称" prop="customerName">
            <el-input
              v-model="queryParams.customerName"
              placeholder="请输入关键词"
              clearable
            />
          </el-form-item>
          <el-form-item label="所属行业" prop="customerIndustry">
            <maindata
              v-model="queryParams.customerIndustry"
              data-type="multSelect"
              data-key="crmCustomerIndustry"
            ></maindata>
          </el-form-item>
          <el-form-item label="客户来源" prop="customerSource">
            <maindata
              v-model="queryParams.customerSource"
              data-type="singleSelect"
              data-key="crmCustomerSource"
            ></maindata>
          </el-form-item>
          <el-form-item label="客户等级" prop="customerDegree">
            <maindata
              v-model="queryParams.customerDegree"
              data-type="singleSelect"
              data-key="crmCustomerDegree"
            ></maindata>
          </el-form-item>
          <el-form-item label="客户类型" prop="customerCategory">
            <maindata
              v-model="queryParams.customerCategory"
              data-type="singleSelect"
              data-key="crmCustomerCategory"
            ></maindata>
          </el-form-item>
          <el-form-item label="跟进状态" prop="customerStatus">
            <maindata
              v-model="queryParams.customerStatus"
              data-type="singleSelect"
              data-key="crmCustomerStatus"
            ></maindata>
          </el-form-item>
          <el-form-item>
            <div style="margin: 0 0 0 22px">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                v-preventReClick
                @click="handleQuery"
                >搜索</el-button
              >
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
                >重置</el-button
              >
            </div>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :show-search.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          border
          v-loading="loading"
          :data="dataList"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column
              label="客户编码"
              key="customerId"
              prop="customerId"
              :show-overflow-tooltip="true"
            /> -->
          <el-table-column
            min-width="200"
            fixed
            label="客户名称"
            key="customerName"
            prop="customerName"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link @click="handleView(scope.row)" type="primary">{{
                scope.row.customerName
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            min-width="300"
            label="公司名称"
            key="companyName"
            prop="companyName"
          />
          <el-table-column
            min-width="100"
            label="客户类型"
            key="customerCategoryName"
            prop="customerCategoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100"
            label="客户来源"
            key="customerSourceName"
            prop="customerSourceName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100"
            label="客户等级"
            key="customerDegreeName"
            prop="customerDegreeName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="所属行业"
            align="center"
            key="customerIndustryName"
            prop="customerIndustryName"
            min-width="150"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100"
            label="跟进状态"
            align="center"
            key="customerStatusName"
            prop="customerStatusName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100"
            label="营销总次数"
            align="center"
            key="customerPurchaseCount"
            prop="customerPurchaseCount"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="160"
            label="最后跟进时间"
            align="center"
            key="customerLastFollowTime"
            prop="customerLastFollowTime"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.customerLastFollowTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            label="未跟进天数"
            align="center"
            key="customerNoFollowDays"
            prop="customerNoFollowDays"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100"
            label="创建人"
            align="center"
            key="creatorName"
            prop="creatorName"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="创建时间" prop="createTime" min-width="170">
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            align="center"
            min-width="180"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleView(scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <editCustomer
        v-model="customerEntity.guid"
        @editResult="editResult"
      ></editCustomer>
    </el-dialog>
    <el-drawer
      :title="drawer.title"
      :visible.sync="drawer.visible"
      direction="rtl"
      :with-header="false"
      :destroy-on-close="true"
      size="70%"
    >
      <viewCustomer v-model="drawer.customer"></viewCustomer>
    </el-drawer>
  </div>
</template>

<script>
import { listCustomer, delCustomer } from '@/api/crm/customer'
import maindata from '@/components/MainData/index.vue'
import viewCustomer from '@/views/system/crm/common/viewCustomer.vue'
import editCustomer from '@/views/system/crm/common/editCustomer.vue'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'Customer',
  components: {
    maindata,
    editCustomer,
    viewCustomer
  },
  data() {
    return {
      windowHeight: 0,
      drawer: {
        title: '',
        visible: false,
        customer: {}
      },
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        customerName: undefined,
        customerSource: undefined,
        customerDegree: undefined,
        customerCategory: undefined,
        customerStatus: undefined,
        customerIndustry: undefined
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      customerEntity: {}
    }
  },
  created() {
    this.initWindowHeight()
  },
  mounted() {
    this.getRolePriList()
    this.getList()
  },
  methods: {
    initWindowHeight() {
      let pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      this.windowHeight = pageHeight - 140
      console.log(this.windowHeight)
    },
    /** 查询角色列表 */
    getRolePriList() {
      let data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      listCustomer(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    editResult() {
      this.open = false
      this.getList()
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.customerEntity = {}
      this.title = '添加客户'
      this.open = true
    },
    handleView(row) {
      this.drawer.title = row.customerName
      this.drawer.customer = row
      this.drawer.visible = true
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.customerEntity = row
      this.title = '修改客户'
      this.open = true
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.customerName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delCustomer(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style scoped lang="scss">
.cell-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dialog-footer {
  text-align: center;
}

.el-drawer__header {
  margin-bottom: 10px;
}

.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}

.drawerContent {
  display: flex;

  .left {
    width: 69%;
    margin: 0 0 0 10px;
  }

  .right {
    width: 28%;
    margin: 0 0 0 10px;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    padding: 8px;
    .header {
      padding: 0 0 0 10px;

      span {
        line-height: 40px;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
