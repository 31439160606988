import {request,post} from '@/plugins/request';


// 查询菜单详细
export function getMenu(data) {
  return post('/rbac/v0.1/rbac/menus/menus/detail',data);
}

export function menuTreeList(data) {
  return post('/rbac/v0.1/rbac/menus/menus/getMenuTreeListByAppId',data);
}


// 新增菜单
export function addMenu(data) {
  return post('/rbac/v0.1/rbac/menus/menus/create',data);
}

// 修改菜单
export function updateMenu(data) {
  return post('/rbac/v0.1/rbac/menus/menus/update',data);
}

// 删除菜单
export function delMenu(data) {
  return post('/rbac/v0.1/rbac/menus/menus/delete',data);
}
