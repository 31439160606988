import { request, post } from '@/plugins/request';

// 查询详细
export function get(data) {
    return post('/maindata/v0.1/maindata/maindata/item/detail', data);
}

export function list(data) {
    return post('/maindata/v0.1/maindata/maindata/item/list', data);
}
// 新增
export function add(data) {
    return post('/maindata/v0.1/maindata/maindata/item/create', data);
}
// 修改
export function update(data) {
    return post('/maindata/v0.1/maindata/maindata/item/update', data);
}

// 删除
export function del(data) {
    return post('/maindata/v0.1/maindata/maindata/item/delete', data);
}
