import {request,post} from '@/plugins/request';

  // 查询详细
  export function getOpportunity(data) {
    return post('/crm/v0.1/crm/crm/opportunities/detail',data);
  }
  
  export function listOpportunity(data) {
    return post('/crm/v0.1/crm/crm/opportunities/list',data);
  }
  // 新增
  export function addOpportunity(data) {
    return post('/crm/v0.1/crm/crm/opportunities/create',data);
  }
  // 修改
  export function updateOpportunity(data) {
    return post('/crm/v0.1/crm/crm/opportunities/update',data);
  }
  
  // 删除
  export function delOpportunity(data) {
    return post('/crm/v0.1/crm/crm/opportunities/delete',data);
  }