<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="loading">
          <el-row>
            <el-col>
              <el-form-item label="相关商机" prop="opportunityGuid">
                <el-select v-model="form.opportunityGuid" placeholder="请选择">
                        <el-option v-for="dict in opportunityOptions" :key="dict.guid" :label="dict.opportunityName+' ['+dict.opportunityContactName+']'"
                            :value="dict.guid"></el-option>
                        </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
                <el-col>
                    <el-form-item label="关联联系人" prop="contactGuid">
                        <el-select v-model="form.contactGuid" placeholder="请选择">
                        <el-option v-for="dict in contactOptions" :key="dict.guid" :label="dict.contactName+' ['+dict.contactTelephone+']'"
                            :value="dict.guid"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
          <el-row>
            <el-col>
              <el-form-item label="跟进类型" prop="followCategory">
                <maindata v-model="form.followCategory" dataType="singleSelect"
                            dataKey="crmFollowCategory"></maindata>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="跟进记录" prop="followContent">
                <el-input type="textarea"  v-model="form.followContent" placeholder="请输入跟进记录"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="附件" prop="followAttachments" >
                <ImageUpload v-model="form.followAttachments" @syncFileIds="saveFollowAttachments"></ImageUpload>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col>
              <el-form-item label="备注" prop="comment">
                <el-input type="textarea" v-model="form.comment" />
              </el-form-item>
            </el-col>
          </el-row> -->
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm" v-preventReClick
            >确 定</el-button
          >
          <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {listContact} from '@/api/crm/contact'
import {listOpportunity} from '@/api/crm/opportunity'
import {addFollow,updateFollow,getFollow} from '@/api/crm/follow'
import maindata from '@/components/MainData/index.vue'
import ImageUpload from '@/components/ImageUpload/indexNew.vue'
export default {
    name:'editFollow',
    data(){
        return {
           loading:false,
           form:{},
           myValue:this.value,
           customerGuid:this.customer_guid,
           opportunityOptions:[],//关联商机列表
           contactOptions:[],//关联联系人列表
           // 表单校验
            rules: {
                contactGuid: [
                    { required: true, message: '相关联系人不能为空', trigger: 'blur' }
                ],
                followCategory: [
                    { required: true, message: '跟进类型不能为空', trigger: 'blur' }
                ],
                followContent: [
                    { required: true, message: '跟进内容不能为空', trigger: 'blur' }
                ]
            }
        }
    },
    components:{
      maindata,ImageUpload
    },
    props:{
        value:{
            type:String,
            default:''
        },
        customer_guid:{
            type:String,
            default:''
        }
    },
    created(){

    },
    mounted(){
      this.getOpportunitiesList();
      this.getContactList();
      this.initForm();
    },
    methods:{
      //  获取商机列表
       getOpportunitiesList(){
            listOpportunity({
                currentPage: 1,
                pageSize: 100,
                customerGuid:this.customerGuid
            }).then(res=>{
                if(res.data.success){
                    this.opportunityOptions=res.data.result.records;
                }else{
                    console.log(res.data.message)
                }
            })
       },
      //  获取关联联系人
       getContactList(){
            listContact({
                currentPage: 1,
                pageSize: 100,
                customerGuid:this.customerGuid
            }).then(res=>{
                if(res.data.success){
                    this.contactOptions=res.data.result.records;
                }else{
                    console.log(res.data.message)
                }
            })
       },
       //上传附件的回调
       saveFollowAttachments(v){
        this.form.followAttachments = v;
       },
        // 表单重置
        reset() {
            this.form = {}
            this.$resetForm('form')
        },
        initForm(){
            this.reset();
           if(!this.myValue){
             this.reset();
             return;
           };
           this.loading=true;
            getFollow({
                guid:this.myValue
            }).then(res=>{
                if(res.data.success){
                    this.form=res.data.result;
                }else{
                    console.log(res.data.message);
                }
                this.loading=false;
            })
        },
        cancel(){
            this.$emit("editResult","close")
        },
        /** 提交按钮 */
      submitForm: function () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.customerGuid=this.customerGuid;
            console.log(this.form)
            if (this.form.guid != undefined) {
              updateFollow(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('修改成功')
                  this.$emit("editResult","update")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            } else { 
              addFollow(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('新增成功')
                  this.$emit("editResult","add")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            }
          }
        })
       }
    },
    watch:{
       value(newValue){
        this.myValue=newValue;
        this.initForm();
       }
    }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
    text-align: center;
}
</style>