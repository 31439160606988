<template>
  <div class="app-container">
    <div class="showarea" ref="showareaD">
      <div class="chat-title">欢迎,请在下方进行提问</div>
      <div
        v-for="(message, index) in messages"
        :class="[message.role == 'user' ? 'user-question' : 'ai-answer']"
        :key="index"
      >
        <div class="message">
          <div class="msg-icon">
            <!-- <el-avatar :icon="[
                message.role == 'user'
                  ? 'el-icon-s-custom'
                  : 'el-icon-s-custom'
              ]"></el-avatar> -->
            <img
              v-if="message.role == 'user'"
              src="../../../assets/img/icon1.png"
              alt=""
            />
            <img v-else src="../../../assets/img/machine.png" alt="" />
          </div>
          <div class="msg-content">
            <img src="/static/loading-point.gif" v-if="!message.content" />
            <div v-else v-html="markedText(message.content)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <div class="border-box"></div>
      <el-form
        class="el-form-box"
        ref="formMessage"
        :model="formMessage"
        @submit.native.prevent
      >
        <el-form-item label="" prop="key" style="flex: 1">
          <el-input
            v-model="formMessage.key"
            placeholder="请输入..."
            @keyup.enter.native="submitAi"
          >
            <el-button
              slot="append"
              :icon="submitIcon"
              type="primary"
              @click="submitAi"
              v-loading="formMessageLoading"
            ></el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="tips">
        所有生成的内容由人工智能模型自动生成，其准确性和完整性不能保证，也不代表我们的态度和观点。
      </div>
    </div>
  </div>
</template>
<script>
import { chat, getChatStream } from '@/api/ai/chat'
import { marked } from 'marked'
import { TOKEN_KEY } from '@/utils/constant'
import ImageUpload from '@/components/ImageUpload/index'
import hljs from 'highlight.js' // 引入 highlight.js
import 'highlight.js/styles/monokai-sublime.css'
export default {
  name: 'AiChat',
  data() {
    return {
      submitIcon: 'el-icon-position',
      formMessageLoading: false,
      formMessage: {
        key: undefined,
        prompt: '',
        history: []
      },
      messages: [],
      rulesformMessage: {
        key: [{ required: true, message: '' }]
      },
      rendererMD: null
    }
  },
  mounted() {
    //this.rendererMD = new marked.Renderer()
    const windowHeight = window.innerHeight
    this.$refs.showareaD.style.height = windowHeight - 100 + 'px'
    console.log(this.$refs.showareaD.style.height)
  },
  created() {},
  methods: {
    markedText(code) {
      // marked.setOptions({
      //   renderer: this.rendererMD,
      //   highlight: function (code) {
      //     return hljs.highlightAuto(code).value
      //   },
      //   pedantic: false,
      //   gfm: true,
      //   tables: true,
      //   breaks: false,
      //   sanitize: false,
      //   smartLists: true,
      //   smartypants: false,
      //   xhtml: false
      // })
      return marked(code)
    },
    messageScrollToBottom(){
      this.$nextTick(()=>{
        let scrollHeight=this.$refs.showareaD.scrollHeight;
        console.log('scrollHeight:'+scrollHeight);
        this.$refs.showareaD.scrollTo({
        top: scrollHeight+200,
        behavior: 'smooth',
       });
      })
    },
    // 发送消息
    submitAi() {
      if (this.submitIcon == 'el-icon-loading') return
      if (!this.formMessage.key) return

      let historys = []
      this.messages.forEach((item) => {
        historys.push(item.content)
      })
      let msg = {}
      msg.role = 'user'
      msg.content = this.formMessage.key
      this.messages.push(msg)
      this.submitIcon = 'el-icon-loading'

      this.formMessage.history = historys
      msg = {}
      msg.role = 'assistant'
      msg.content = ''
      this.messages.push(msg)
      this.messageScrollToBottom();
      chat(this.formMessage).then((res) => {
        this.formMessage.key = ''
        this.submitIcon = 'el-icon-position'
        if (res.data.success) {
          // 找到最后一个message，进行content替换
          const result = res.data.result
          this.messages[this.messages.length - 1].content = result
        } else {
          this.messages[this.messages.length - 1].content =
            '我的程序出问题了……错误：' + res.data.message
        }
        this.messageScrollToBottom();
      })
    }
  },
  components: {
    ImageUpload // 注册上传组建
  }
}
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
pre {
  padding: 5px 5px 5px 5px;
}
.tips {
  text-align: center;
  margin: 0 0 15px 0;
  /* transform: scale(.5);
    transform-origin: center top; */
  /* color: #878aab; */
  color: #b4b9c3;
  font-size: 12px;
}
.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
  background: #f5f5f5;
}

.bottom_box {
  position: fixed;
  bottom: 0;
  /* left: 200px; */
  width: calc(100% - 240px);
  /* padding: 0 20px; */
  background-color: #fff;
}

.el-form-box {
  overflow: hidden;
  padding-top: 30px;
  background: #fff;
  /* height: 100px; */
  width: 80%;
  margin: 0 auto;
  display: flex;
}
.el-form-item {
  margin-bottom: 10px;
}
.border-box {
  border-top: 1px solid #eeeeee;
}

.inputKey {
  max-height: 200px;
}

.keyClass {
  max-height: 200px;
  height: 24px;
  overflow-y: hidden;
}

.showarea {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  padding-bottom: 150px;
}

.msgSubmit {
  overflow: hidden;
  text-align: left;
  line-height: 1.5rem;
  position: fixed;
  width: 100%;
  height: 100px;
  padding-top: 20px;
  background: #fff;
  bottom: 0;
}

.message {
  margin: 0 auto;
  display: flex;
  color: #fff;
}

.user-question .message {
  flex-direction: row-reverse;
}

.msg-icon {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  background: #2d6ce9;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.msg-icon img {
  width: 30px;
  height: 30px;
  border-radius: 100px;
}

.msg-content {
  /* flex: 1; */
  background: #89d961;
  margin: 0 5px;
  border-radius: 10px;
  padding: 0 5px 0 5px;
  min-width: 100px;
  text-align: left;
  /* font-size: 14px; */
  line-height: 24px;
  word-break: break-all;
  color: #232323;
}

.user-question .msg-content {
  background: #fff;
}

.el-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.ai-answer {
  padding: 8px 0 0 0;
}

.user-question {
  padding: 8px 0 0 0;
}

.user-question .message {
}

.wrap {
  caret-color: black;
}

.el-button--primary {
  --tw-bg-opacity: 1;
  background-color: #eeeeee;
}

.el-button {
  border: 0px;
  line-height: 16px;
}

@media only screen and (max-width: 768px) {
  [class*='bottom_box'] {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    width: 100%;
    background: #fff;
  }

  [class*='showarea'] {
    width: 100%;
  }

  [class*='el-form-box'] {
    width: 100%;
  }

  [class*='showarea'] {
    width: 100%;
  }
}
</style>