<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="68px"
        >
          <el-form-item label="项目名称" prop="name" label-width="100px">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入项目名称"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="siteList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            align="center"
            key="guid"
            prop="guid"
            width="230"
          />
          <el-table-column
            label="项目名称"
            align="center"
            key="name"
            prop="name"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="GroupId"
            align="center"
            key="groupId"
            prop="groupId"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="ArtifactId"
            align="center"
            key="artifactId"
            prop="artifactId"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="包路径"
            align="center"
            key="packagePath"
            prop="packagePath"
            :show-overflow-tooltip="true"
          />

          <el-table-column
            label="创建时间"
            align="center"
            prop="created"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="160"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-download"
                v-preventReClick
                @click="handleGenerate(scope.row)"
                >代码生成</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <div>
        <el-steps
          :active="dialog.step"
          align-center
          process-status="process"
          finish-status="success"
        >
          <el-step title="项目信息"></el-step>
          <el-step title="模块信息"></el-step>
        </el-steps>
        <div class="dialog-form">
          <el-form
            ref="formStep1"
            :model="form"
            :rules="rules"
            label-width="150px"
            v-show="dialog.step === 0"
          >
            <el-row>
              <el-col>
                <el-form-item label="工程名Name" prop="name">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入工程名Name 例如：douledi-rbac"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <el-form-item label="GroupId" prop="groupId">
                  <el-input v-model="form.groupId" placeholder="GroupId" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="ArtifactId" prop="artifactId">
                  <el-input
                    v-model="form.artifactId"
                    placeholder="请输入ArtifactId"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="包名" prop="packagePath">
                  <el-input
                    v-model="form.packagePath"
                    placeholder="请输入包名 例如：rbac,log"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="数据库名称" prop="databaseName">
                  <el-input
                    v-model="form.databaseName"
                    placeholder="请输入数据库名称"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="项目描述" prop="description">
                  <el-input
                    v-model="form.description"
                    placeholder="请输入项目描述"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            ref="formStep2"
            :rules="rules"
            label-width="80px"
            v-show="dialog.step === 1"
          >
            <el-form-item label="模块">
              <!--自定义数据权限-->
              <el-button type="primary" @click="addSchema" v-preventReClick
                >添加</el-button
              >

              <el-table
                :data="schemaData"
                ref="schema"
                border
                style="width: 100%"
              >
                <el-table-column
                  prop="shcemaName"
                  label="模块名称"
                  style="width: 100px"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.name" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="schemaRemark"
                  label="模块备注"
                  style="width: 100px"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="tableList"
                  label="表信息"
                  style="width: 300px"
                >
                  <template slot-scope="scope">
                    <div v-for="item in scope.row.tableList" :key="item.name">
                      {{ item.name }}({{ item.path }})
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" style="width: 50px">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-edit"
                      v-preventReClick
                      @click="handleTableEdit(scope.$index, scope.row)"
                      >编辑表关系</el-button
                    >
                    <el-button
                      size="mini"
                      type="text"
                      icon="el-icon-delete"
                      v-preventReClick
                      @click="handleSchemaDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button v-preventReClick @click="previous" v-if="dialog.step > 0"
            >上一步</el-button
          >
          <el-button
            type="primary"
            v-preventReClick
            @click="next"
            v-if="dialog.step < 1"
            >下一步</el-button
          >
          <el-button
            type="primary"
            v-preventReClick
            @click="submitForm"
            v-if="dialog.step === 1"
            >提交</el-button
          >
          <el-button @click="cancel">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="tableOpen"
      width="800px"
      append-to-body
    >
      <el-form label-width="80px">
        <el-form-item label="模块">
          <el-button type="primary" @click="addTable" v-preventReClick
            >添加</el-button
          >
          <el-table :data="tableData" ref="table" border style="width: 100%">
            <el-table-column prop="tableName" label="表名称">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" />
              </template>
            </el-table-column>
            <el-table-column prop="schemaRemark" label="表路径(Mapping)">
              <template slot-scope="scope">
                <el-input v-model="scope.row.path" />
              </template>
            </el-table-column>
            <el-table-column label="操作" style="width: 50px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  v-preventReClick
                  @click="handleTableDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-preventReClick @click="submitTableForm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listApp,
  getApp,
  addApp,
  updateApp,
  delApp,
  generateApp
} from '@/api/system/developer'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'generator',
  data() {
    return {
      menuPriPath: [],
      // 遮罩层
      loading: true,
      schemaDataLoading: false,
      tableDataLoading: false,
      generateLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      siteList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      tableOpen: false,
      // 是否显示弹出层（数据权限）
      schemaData: [],
      tableData: [],
      tableDataIndex: undefined,
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      // 新增表单
      dialog: {
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        step: 0
      },
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        appid: undefined,
        enterpriseSecret: undefined
      },
      // 表单参数
      form: {
        schemaList: []
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '系统Name不能为空', trigger: 'blur' }
        ],
        groupId: [
          { required: true, message: 'GroupId不能为空', trigger: 'blur' }
        ],
        artifactId: [
          { required: true, message: 'ArtifactId不能为空', trigger: 'blur' }
        ],
        packagePath: [
          { required: true, message: '包路径不能为空', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '系统描述不能为空', trigger: 'blur' }
        ],
        databaseName: [
          { required: true, message: '数据库不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
    //   this.getRolePriList();
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      listApp(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.siteList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /** 下一步 */
    next() {
      let form = ''
      switch (this.dialog.step) {
        case 0:
          form = 'formStep1'
          break
        case 1:
          form = 'formStep2'
          break
        case 2:
          form = 'formStep3'
          break
      }
      if (form == 'formStep1') {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.dialog.step++
          }
        })
      } else {
        this.dialog.step++
      }
    },
    /** 上一步 */
    previous() {
      this.dialog.step--
    },
    addSchema() {
      var priObj = {
        name: '',
        remark: '',
        tableList: []
      }
      this.schemaData.push(priObj)
    },
    addTable() {
      var priObj = {
        name: '',
        path: ''
      }
      this.tableData.push(priObj)
    },
    handleSchemaDelete(index, row) {
      this.schemaData.splice(index, 1)
    },
    handleTableEdit(index, row) {
      this.tableData = []
      this.tableData = row.tableList
      this.tableDataIndex = index
      this.tableOpen = true
    },
    handleTableDelete(index, row) {
      this.tableData.splice(index, 1)
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    cancelTable() {
      this.tableOpen = false
      this.resetTable()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        orderNum: undefined
      }
      this.schemaData = []
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.dialog.step = 0
      this.title = '添加系统'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      var data = {
        guid: row.guid
      }
      getApp(data).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
          this.schemaData = this.form.schemaList
          this.open = true
          this.dialog.step = 0
          this.title = '修改系统'
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    handleGenerate(row) {
      var data = {
        guid: row.guid
      }
      debugger
      this.loading = true
      generateApp(data).then((res) => {
        if (res.data) {
          this.zipDownload('generator.zip', res.data)
          this.loading = false
        } else if(!res.data.success) {
          this.$msgError(res.data.message);
          this.loading = false
        }
      })
    },
    zipDownload(name, data) {
      let link = document.createElement('a')
      let blob = new Blob([data])
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    submitTableForm: function () {
      
      let schemaIndex = this.tableDataIndex
      if (schemaIndex) {
        this.schemaData[this.tableDataIndex].tableList = this.tableData
      }
      this.tableOpen = false
    },

    /** 提交按钮 */
    submitForm: function () {
      debugger
      this.$refs['formStep1'].validate((valid) => {
        if (valid) {
          this.form.schemaList = []
          this.form.tableList = []
          this.form.applicationType = 2 //微服务
          this.form.schemaList = this.schemaData
          if (this.form.guid != undefined) {
            updateApp(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addApp(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除系统名称为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delApp(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style>
.dialog-footer {
  position: relative;
  text-align: center;
}
</style>
