import {post} from '@/plugins/request';
// 新增
export function addOrg(data) {
    return post('/rbac/v0.1/rbac/org/org/create',data);
}

// 修改
export function updateOrg(data) {
    return post('/rbac/v0.1/rbac/org/org/update',data);
}

// 删除
export function delOrg(data) {
    return post('/rbac/v0.1/rbac/org/org/delete',data);
}

// 详情
export function getOrg(data) {
    return post('/rbac/v0.1/rbac/org/org/detail',data);
}
// 列表
export async function listOrg(data) {
    return post('/rbac/v0.1/rbac/org/org/list',data);
}
// 列表tree
export async function listOrgTree(data) {
    return post('/rbac/v0.1/rbac/org/org/listTreeByPid',data);
}