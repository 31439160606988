<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col >
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="90px"
        >
          <el-form-item label="模型名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入模型名称关键字"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item label="模型Key" prop="key">
            <el-input
              v-model="queryParams.key"
              placeholder="请输入模型Key"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item label="模型分类" prop="category" >
              <el-select v-model="queryParams.category" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
           <el-form-item label="企业标识" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
            />
          </el-form-item>
          <el-form-item >
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
       
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="modelList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="主键"
            align="center"
            key="id"
            prop="id"
            width="80"
          />
          <el-table-column
            label="模型名称"
            align="center"
            key="name"
            prop="name"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="模型Key"
            align="center"
            key="key"
            prop="key"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="模型分类"
            align="center"
            prop="category"
          >
          <template slot-scope="scope">
              <span>{{ $selectDictLabel(categoryOptions,scope.row.category) }}</span>
            </template>
        </el-table-column>
          <el-table-column
            label="企业标识"
            align="center"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="模型版本号"
            align="center"
            key="version"
            prop="version"
         
            width="120"
          />
          <el-table-column
            label="最新部署ID"
            align="center"
            key="deploymentId"
            prop="deploymentId"
         
            width="120"
          />
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
         
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="上次修改时间"
            align="center"
            prop="createTime"
         
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.changed) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="300"
            class-name="small-padding fixed-width"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
              @click="goWorkflow(scope.row)"
              icon="el-icon-set-up"
              size="mini"
              type="text"
              v-preventReClick
            >设计</el-button>
            <el-button
              @click="publishWorkflow(scope.row)"
              icon="el-icon-position"
              size="mini"
              type="text"
              v-preventReClick
            >部署</el-button>
            <!-- <el-button
              @click="listPublishWorkFlow(scope.row)"
              icon="el-icon-document"
              size="mini"
              type="text"
              v-preventReClick
            >部署列表</el-button> -->
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col >
            <el-form-item label="模型名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入系统名称" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col >
            <el-form-item label="模型Key" prop="key" >
              <el-input v-model="form.key" placeholder="请输入模型Key" :disabled="keyDisabled" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col >
            <el-form-item label="模型分类" prop="category" >
              <el-select v-model="form.category" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col >
            <el-form-item label="模型版本号" prop="version">
              <el-input v-model="form.version" placeholder="请输入模型版本号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col >
            <el-form-item label="企业标识" prop="enterpriseSecret">
              <el-input v-model="form.enterpriseSecret" placeholder="请输入系统所属企业标识" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick>确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
<el-dialog :title="workFlowTitle" :visible.sync="openEditor" width="90%" append-to-body>
  <iframe :src="editorSrc" frameborder="0" class="iframe"></iframe>
</el-dialog>
<el-dialog :title="deployListTitle" :visible.sync="openDeployList" width="800px" append-to-body>
   <el-form
          :model="queryParamsDeploy"
          ref="queryFormDeploy"
          :inline="true"
          v-show="showSearchDeploy"
          label-width="68px"
        >
          <el-form-item label="部署名称" prop="name">
            <el-input
              v-model="queryParamsDeploy.name"
              placeholder="请输入部署名称关键字"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQueryDeploy"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQueryDeploy"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
 <el-table
          v-loading="deployLoading"
          :data="deployModelList"
          @selection-change="handleSelectionChange"
        >
         <el-table-column
            label="流程定义ID"
            align="center"
            key="id"
            prop="id"
            width="120"
          />
          <el-table-column
            label="流程定义版本号"
            align="center"
            key="version"
            prop="version"
            width="120"
          />
          <el-table-column
            label="部署ID"
            align="center"
            key="deploymentId"
            prop="deploymentId"
         
            width="120"
          />
          <!-- <el-table-column
            label="部署名称"
            align="center"
            key="name"
            prop="name"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="模型Key"
            align="center"
            key="key"
            prop="key"
            :show-overflow-tooltip="true"
          /> -->
          
           <el-table-column
            label="部署时间"
            align="center"
            prop="createTime"
         
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="230"
            class-name="small-padding fixed-width"
            
          >
            <template slot-scope="scope">
               <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="viewDeploy(scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="deployDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
         <pagination
          v-show="deployTotal > 0"
          :total="deployTotal"
          :page.sync="queryParamsDeploy.currentPage"
          :limit.sync="queryParamsDeploy.pageSize"
          @pagination="listPublishWorkFlow"
        />
</el-dialog>
<el-dialog title="流程图" :visible.sync="openLct" width="50%" append-to-body>
  <el-image
      :src="lctUrl"></el-image>
</el-dialog>
  </div>
</template>

<script>
  import {
    listModel,
    getModel,
    addModel,
    updateModel,
    delModel,
    delProcessDefinition,
    getProcessDefineList,
    deployModel,
    viewProcessDefinitionImage
  } from '@/api/system/activiti';
  import {getMenuPriByMenuId} from '@/api/menu/index';
   import config from '@/components/Config'
  import { TOKEN_KEY } from '@/utils/constant'
  import { ACTIVITI } from '@/utils/activitiConstant'
  export default {
    name: 'app',
    data() {
      return {
        apiUrl: config.apiUrl,// 后台部署的api服务
        token: sessionStorage.getItem(TOKEN_KEY),
        workFlowTitle:'',
        deployListTitle: '',
        menuPriPath: [],
        modelRow:{},
        lctUrl:'',
        openLct:false,
        keyDisabled:false,
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        showSearchDeploy: true,
        // 总条数
        total: 0,
        deployTotal: 0,
        // 表格数据
        modelList: [],
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        openEditor: false,
        openDeployList: false,
        deployLoading: false,
        deployModelList:[],
        editorSrc:'',
        // 是否显示弹出层（数据权限）
        openDataScope: false,
        menuExpand: false,
        menuNodeAll: false,
        deptExpand: true,
        deptNodeAll: false,
        // 日期范围
        dateRange: [],
        // 状态数据字典
        statusOptions: [],
        categoryOptions: ACTIVITI.CATEGORY,
        // 查询参数
        queryParams: {
          currentPage: 1,
          pageSize: 10,
          name: undefined,
          key:undefined,
          category:undefined,
          enterpriseSecret:undefined
        },
         queryParamsDeploy: {
          currentPage: 1,
          pageSize: 10,
          name: undefined,
          key:undefined,
          enterpriseSecret:undefined
        },
        // 表单参数
        form: {},
        defaultProps: {
          children: 'childrens',
          label: 'name'
        },
        // 表单校验
        rules: {
          name: [
            { required: true, message: '模型名称不能为空', trigger: 'blur' }
          ],
          category: [
            { required: true, message: '模型分类不能为空', trigger: 'blur' }
          ],
          key: [
            { required: true, message: '模型Key不能为空', trigger: 'blur' }
          ],
           version: [
            { required: true, message: '模型版本号不能为空', trigger: 'blur' },
            { pattern: /^[1-9]\d*$/, message: '只能输入正整数',  }
          ],
          enterpriseSecret: [
            { required: true, message: '企业标识不能为空', trigger: 'blur' }
          ]
        }
      };
    },
    computed:{
        
    },
    mounted() {
      window.getMyVue = this;
    },
    created() {
      this.getList();
      this.getRolePriList();
    },
    methods: {
      /** 查询角色列表 */
    getRolePriList(){
      // debugger
      var data = {
         menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then(
            res => {
              if(res.data.success){
                for (var i = 0; i < res.data.result.length; i++) {
                  this.menuPriPath.push(res.data.result[i].privilegeAction);
                }
              }else{
                  this.$msgError(res.message);
              }
            }
      );
    },
      getList() {
        this.loading = true;
        listModel(JSON.stringify(this.queryParams)).then(
            res=>{
              if(res.data.success){
                this.modelList = res.data.result.records;
                this.total = res.data.result.total;
                this.loading = false;
              }else{
                this.$msgError(res.message);
              }
              
            }
        );
      },
      goWorkflow(row){
          //   this.$router.push({
          //   path: '/system/activiti/model/design',
          //   query:{
          //     modelId:row.id,
          //   }
          // });
          this.editorSrc= '/static/activiti/modeler.html?modelId=' + row.id;
          this.workFlowTitle="流程设计器-["+row.name+"]";
          this.openEditor=true;
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
      // 取消按钮（数据权限）
      cancelDataScope() {
        this.openDataScope = false;
        this.reset();
      },
      // 表单重置
      reset() {

        this.form = {
            guid: undefined,
            name: undefined,
            key: undefined,
            enterpriseSecret:undefined,
          };
        this.$resetForm('form');
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.currentPage = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = [];
        this.$resetForm('queryForm');
        this.handleQuery();
      },
      /** 搜索按钮操作 */
      handleQueryDeploy() {
        this.queryParamsDeploy.currentPage = 1;
        this.listPublishWorkFlow(this.modelRow);
      },
      /** 重置按钮操作 */
      resetQueryDeploy() {
        this.dateRange = [];
        this.$resetForm('queryFormDeploy');
        this.handleQueryDeploy();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.roleId);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      },
      publishWorkflow(row){
          var data={
            "modelId": row.id
          }
          this.loading=true;
          deployModel(data).then(response => {
            let res=response.data;
             
            if(res.success){
              this.$msgSuccess('发布成功!');
              this.handleQuery();
            }else{
              this.$msgError(res.message);
            }
            this.loading=false;
          });
      },
      listPublishWorkFlow(row){
        this.modelRow=row;
          this.deployListTitle="["+row.name+"]部署列表";
          var data={
            "key": this.modelRow.key,
            "name":this.queryParamsDeploy.name
          }
          this.openDeployList=true;
          this.deployLoading=true;
          getProcessDefineList(data).then(response => {
            let res=response.data;
            if(res.success){
              this.deployModelList=res.result.records;
              this.deployTotal = res.data.result.total;
            }else{
              this.$msgError(res.message);
            }
             this.deployLoading=false;
          });
      },
      viewDeploy(row){
        var data={
          "deploymentId": row.deploymentId
        }
        
        viewProcessDefinitionImage(data).then(res=>{
              debugger
              let url = window.URL.createObjectURL(new Blob([res.data]));
              this.lctUrl=url;
              this.openLct=true;

        });
      },
      deployDelete(row){
        debugger
        this.$confirm(
          '是否确认删除部署名称为"' + row.name + '"的数据项?',
          '警告',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
            let data={
              deploymentId: row.deploymentId
            }
            this.deployLoading=true;
            delProcessDefinition(data).then(res=>{
              if(res.data.success){
                  this.listPublishWorkFlow(this.modelRow);
                  this.$msgSuccess('删除成功');
              }else{
                  this.$msgError(res.data.message);
                  this.deployLoading=false;
              }
            });
            
          });
         
      },
      /** 新增按钮操作 */
      handleAdd() {
        this.reset();
        this.open = true;
        this.title = '添加模型';
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset();
        this.keyDisabled=true;
        var data={
          "id": row.id
        }
        getModel(data).then(res => {
          if(res.data.success){
            this.form = res.data.result;
            this.open = true;
            this.title = '修改模型';
          }else{
            this.$msgError(res.data.message);
          }
          
        });
      },
      
     
      /** 提交按钮 */
      submitForm: function () {
        this.$refs['form'].validate(valid => {
          if (valid) {
            if (this.form.id != undefined) {
              updateModel(this.form).then(res => {
                if(res.data.success){
                  this.$msgSuccess('修改成功');
                  this.open = false;
                  this.getList();
                }else{
                  this.$msgError(res.data.message);
                }
              });
            } else {
              addModel(this.form).then(res => {
                if(res.data.success){
                  this.$msgSuccess('新增成功');
                  this.open = false;
                  this.getList();
                }else{
                 this.$msgError(res.data.message);
                }
              });
            }
          }
        });
      },
     
      /** 删除按钮操作 */
      handleDelete(row) {
        this.$confirm(
          '是否确认删除模型名称为"' + row.name + '"的数据项?',
          '警告',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            debugger
            this.loading=true;
             let data={
              id: row.id
            }
            delModel(data).then(res=>{
              debugger
              if(res.data.success){
                 this.getList();
                 this.$msgSuccess('删除成功');
              }else{
                this.$msgError(res.data.message);
                this.loading=false;
              }
               
            })
            
          });
      },
    }
  };
</script>
<style>
  .iframe {
    width: 100%;
    height: calc(100vh - 85px);
  }
  /* .el-dialog__close{
    display: none;
  }
  .el-icon-close{
    display: none;
  }
 .el-dialog__body{
  padding:0px 0 0 0;
 } */
</style>
