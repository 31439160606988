<template>
  <div class="content">
    Welcome to EDP System.
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content{
    text-align: center;
padding: 50px;
}
</style>