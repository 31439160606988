<template>
  <div>
    <div class="search">
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        v-show="showSearch"
        label-width="90px"
      >
        <el-form-item label="产品名称" prop="purchaseProductName">
          <el-input
            v-model="queryParams.purchaseProductName"
            placeholder="请输入关键词"
            clearable
            size="small"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            v-preventReClick
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="list">
      <el-table border v-loading="loading" :data="dataList">
        <el-table-column
          fixed
          min-width="200px"
          label="产品名称"
          key="purchaseProductName"
          prop="purchaseProductName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          min-width="100px"
          align="right"
          label="产品单价(元)"
          key="purchaseProductPrice"
          prop="purchaseProductPrice"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          min-width="100px"
          align="center"
          label="产品数量"
          key="purchaseProductNum"
          prop="purchaseProductNum"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          min-width="100px"
          align="right"
          label="产品总价(元)"
          key="purchaseProductTotal"
          prop="purchaseProductTotal"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          label="采购日期"
          key="purchaseDate"
          prop="purchaseDate"
          width="100px"
        >
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.purchaseDate, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column
                label="创建人"
                align="center"
                key="creatorName"
                prop="creatorName"
                :show-overflow-tooltip="true"
              /> -->
        <el-table-column label="创建时间" prop="createTime" min-width="160">
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          min-width="180"
          class-name="small-padding fixed-width"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              v-preventReClick
              @click="handleView(scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              v-preventReClick
              @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              v-preventReClick
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.currentPage"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <el-divider></el-divider>
    <div style="text-align: center">
      <el-button @click="addPurchase" type="primary">新增采购产品</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      v-if="open"
    >
      <editPurchase
        :customer_guid="myValue"
        v-model="purchaseEntity.guid"
        @editResult="editResult"
      ></editPurchase>
    </el-dialog>
    <el-drawer
      :append-to-body="true"
      :title="purchaseEntity.purchaseProductName"
      :visible.sync="drawer.visible"
      v-if="drawer.visible"
      direction="rtl"
      :with-header="false"
      size="50%"
    >
      <div class="drawerTitle">
        <div class="kh">
          <span
            ><i class="el-icon-user-solid"></i>
            {{ purchaseEntity.purchaseProductName }}</span
          >
        </div>
        <div class="sub">
          <span>创建人：{{ purchaseEntity.creatorName }}</span>
          <span>创建时间：{{ $parseTime(purchaseEntity.created) }}</span>
        </div>
      </div>
      <el-divider><i class="el-icon-arrow-down"></i></el-divider>
      <div class="drawerContent">
        <div class="left">
          <el-tabs type="border-card">
            <el-tab-pane>
              <span slot="label"
                ><i class="el-icon-date"></i> 采购产品信息</span
              >
              <viewPurchase v-model="purchaseEntity"></viewPurchase>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import editPurchase from '@/views/system/crm/common/editPurchase.vue'
import viewPurchase from '@/views/system/crm/common/viewPurchase.vue'
import { listPurchase, delPurchase } from '@/api/crm/purchase'
export default {
  name: 'CustomerPurchase',
  data() {
    return {
      open: false,
      title: '',
      myValue: this.value,
      loading: false,
      dataList: [],
      total: 0,
      purchaseEntity: {},
      drawer: {
        title: '',
        visible: false,
        customer: {}
      },
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 5,
        purchaseProductName: undefined,
        customerGuid: this.value
      },
      showSearch: true
    }
  },
  components: {
    editPurchase,
    viewPurchase
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    //新增联系人
    addPurchase() {
      this.purchaseEntity = []
      this.title = '添加采购产品'
      this.open = true
    },
    editResult(v) {
      this.open = false
      this.getList()
    },
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    resetQuery() {
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    getList() {
      this.loading = true
      listPurchase(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    handleView(row) {
      this.purchaseEntity = row
      this.drawer.visible = true
    },
    handleUpdate(row) {
      this.open = true
      this.title = '修改采购产品'
      this.purchaseEntity = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.purchaseProductName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delPurchase(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
      this.queryParams.customerGuid = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
.el-drawer__header {
  margin-bottom: 10px;
}

.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}

.search {
  text-align: center;
}

ul,
li {
  list-style-type: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

li {
  line-height: 30px;

  .title {
    width: 80px;
    text-align: right;
  }
}

span {
  font-size: 14px;
  color: #72767b;
  display: inline-block;
}
</style>
