import { request, post } from '@/plugins/request';

// 查询详细
export function get(data) {
    return post('/maindata/v0.1/maindata/maindata/type/detail', data);
}
// 查询列表
export function list(data) {
    return post('/maindata/v0.1/maindata/maindata/type/list', data);
}
//根据typeKey查询item
export function listDict(data) {
    return post('/maindata/v0.1/maindata/maindata/type/listDict', data);
}
// 新增
export function add(data) {
    return post('/maindata/v0.1/maindata/maindata/type/create', data);
}
// 修改
export function update(data) {
    return post('/maindata/v0.1/maindata/maindata/type/update', data);
}

// 删除
export function del(data) {
    return post('/maindata/v0.1/maindata/maindata/type/delete', data);
}
