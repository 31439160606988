import enLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
      txtWebSite: '逗乐迪',
      txtWebSiteEn: 'DouLeDi',
      copyRight:
            "<p>集团公司 版权所有 Copyright 2021-2022 douledi.com</p>",
      enLocale,
}


export default zh
