<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      v-loading="loading"
    >
      <el-row>
        <el-col>
          <el-form-item label="商机名称" prop="opportunityName">
            <el-input
              v-model="form.opportunityName"
              placeholder="请输入商机名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="关联联系人" prop="opportunityContactGuid">
            <el-select
              v-model="form.opportunityContactGuid"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in opportunityContactOptions"
                :key="dict.guid"
                :label="dict.contactName + ' [' + dict.contactTelephone + ']'"
                :value="dict.guid"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="商机类型" prop="opportunityCategory">
            <maindata
              v-model="form.opportunityCategory"
              data-type="singleSelect"
              data-key="crmOpportunityCategory"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="商机来源" prop="opportunitySource">
            <maindata
              v-model="form.opportunitySource"
              data-type="singleSelect"
              data-key="crmOpportunitySource"
            >
            </maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="商机阶段" prop="opportunityStep">
            <maindata
              v-model="form.opportunityStep"
              data-type="singleSelect"
              data-key="crmOpportunityStep"
            >
            </maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="商机状态" prop="opportunityStatus">
            <maindata
              v-model="form.opportunityStatus"
              data-type="singleSelect"
              data-key="crmOpportunityStatus"
            >
            </maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="商机获取日期" prop="opportunityGetDate">
            <el-date-picker
              v-model="form.opportunityGetDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="预计销售金额" prop="opportunityPrice">
            <el-input
              style="width: 220px"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              v-model="form.opportunityPrice"
              placeholder="请输入预计销售金额"
            />元
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="预计签单日期" prop="opportunityDate">
            <el-date-picker
              v-model="form.opportunityDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="备注" prop="comment">
            <el-input type="textarea" v-model="form.comment" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm" v-preventReClick
        >确 定</el-button
      >
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {
  getOpportunity,
  listOpportunity,
  delOpportunity,
  addOpportunity,
  updateOpportunity
} from '@/api/crm/opportunity'
import { listContact } from '@/api/crm/contact'
import maindata from '@/components/MainData/index.vue'
export default {
  name: 'EditOpportunity',
  data() {
    return {
      loading: false,
      form: {},
      myValue: this.value,
      customerGuid: this.customer_guid,
      opportunityContactOptions: [], //商机关联联系人列表
      // 表单校验
      rules: {
        opportunityName: [
          { required: true, message: '商机名称不能为空', trigger: 'blur' }
        ],
        opportunityContactName: [
          { required: true, message: '关联联系人不能为空', trigger: 'blur' }
        ],
        opportunitySource: [
          { required: true, message: '商机来源不能为空', trigger: 'blur' }
        ],
        opportunityCategory: [
          { required: true, message: '商机类型不能为空', trigger: 'blur' }
        ],
        opportunityStep: [
          { required: true, message: '商机阶段不能为空', trigger: 'blur' }
        ],
        opportunityStatus: [
          { required: true, message: '商机状态不能为空', trigger: 'blur' }
        ],
        opportunityGetDate: [
          { required: true, message: '商机获取日期不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    maindata
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    customer_guid: {
      type: String,
      default: ''
    }
  },
  created() {},
  mounted() {
    this.getOpportunityContactList()
    this.initForm()
  },
  methods: {
    // 表单重置
    reset() {
      this.form = {}
      this.$resetForm('form')
    },
    initForm() {
      this.reset()
      if (!this.myValue) {
        this.reset()
        return
      }
      this.loading = true
      getOpportunity({
        guid: this.myValue
      }).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
        } else {
          console.log(res.data.message)
        }
        this.loading = false
      })
    },
    cancel() {
      this.$emit('editResult', 'close')
    },
    getOpportunityContactList() {
      listContact({
        currentPage: 1,
        pageSize: 100,
        customerGuid: this.customerGuid
      }).then((res) => {
        if (res.data.success) {
          this.opportunityContactOptions = res.data.result.records
        } else {
          console.log(res.data.message)
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.customerGuid = this.customerGuid
          console.log(this.form)
          if (this.form.guid !== undefined) {
            updateOpportunity(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.$emit('editResult', 'update')
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addOpportunity(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.$emit('editResult', 'add')
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
      this.initForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: center;
}
</style>
