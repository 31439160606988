<template>
  <div>
    <div class="customerInfoContent" v-loading="loading">
      <ul>
        <li>
          <span class="title">客户名称：</span
          ><span class="content">{{ customer.customerName }}</span>
        </li>
        <li>
          <span class="title">客户类型：</span
          ><span class="content">{{ customer.customerCategoryName }}</span>
        </li>
        <li>
          <span class="title">客户来源：</span
          ><span class="content">{{ customer.customerSourceName }}</span>
        </li>
        <li>
          <span class="title">客户等级：</span
          ><span class="content">{{ customer.customerDegreeName }}</span>
        </li>
        <li>
          <span class="title">所属行业：</span
          ><span class="content">{{ customer.customerIndustryName }}</span>
        </li>
        <li>
          <span class="title">意向采购：</span
          ><span class="content">{{ customer.purchaseInfoName }}</span>
        </li>
        <li>
          <span class="title">公司名称：</span
          ><span class="content">{{ customer.customerName }}</span>
        </li>
        <li>
          <span class="title">公司规模：</span
          ><span class="content">{{ customer.companySizeName }}</span>
        </li>
        <li>
          <span class="title">注册资本：</span
          ><span class="content">{{ customer.companyRegisteredCapital }}</span>
        </li>
        <li>
          <span class="title">开票信息：</span
          ><span class="content">{{ customer.invoicingInfo }}</span>
        </li>
        <li>
          <span class="title">公司地址：</span
          ><span class="content">{{ customer.companyAddress }}</span>
        </li>
        <li>
          <span class="title">公司资质：</span
          ><span class="content"
            ><ImageUpload
              v-model="customer.companyQualifactions"
              :view-image="viewImage"
            ></ImageUpload
          ></span>
        </li>
        <li>
          <span class="title">备注：</span
          ><span class="content">{{ customer.comment }}</span>
        </li>
      </ul>
    </div>
    <el-divider></el-divider>
    <div style="text-align: center">
      <el-button @click="editCustomerHandler" type="primary"
        >修改基本信息</el-button
      >
    </div>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      v-if="open"
    >
      <editCustomer
        v-model="customer.guid"
        @editResult="editResult"
      ></editCustomer>
    </el-dialog>
  </div>
</template>

<script>
import { getCustomer } from '@/api/crm/customer'
import ImageUpload from '@/components/ImageUpload/indexNew.vue'
import editCustomer from '@/views/system/crm/common/editCustomer.vue'
export default {
  name: 'CustomerInfo',
  data() {
    return {
      customer: {},
      loading: false,
      myValue: this.value,
      viewImage: true,
      open: false,
      title: ''
    }
  },
  components: {
    ImageUpload,
    editCustomer
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.getCustomerInfo()
  },
  methods: {
    // 获取客户信息
    getCustomerInfo() {
      if (!this.myValue) return
      this.loading = true
      let data = {
        guid: this.myValue
      }
      console.log(this.myValue)
      getCustomer(data).then((res) => {
        if (res.data.success) {
          this.customer = res.data.result
        } else {
          console.log(res.data.message)
        }
        this.loading = false
      })
    },
    editCustomerHandler() {
      this.title = '修改客户'
      this.open = true
    },
    editResult() {
      this.open = false
      this.getCustomerInfo()
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.customerInfoContent {
  display: grid;
  ul,
  li {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  li {
    line-height: 28px;
    margin-bottom: 1px;
    .title {
      width: 20%;
      text-align: right;
      vertical-align: top;
    }
    .content {
      width: 80%;
      background-color: #f5f5f5;
      padding: 0 5px 0 5px;
    }
  }
  span {
    font-size: 14px;
    color: #72767b;
    display: inline-block;
  }
}
</style>
