import {post} from '@/plugins/request'

// 获取路由
export const getMenuTreeByPid = data => {
  return post('/rbac/v0.1/rbac/menus/menus/getMenuTreeByPid',data);
}

// 获取菜单对应的操作权限
export function getMenuPriByMenuId(data){
  return post('/rbac/v0.1/rbac/role/rmp/getListByUserIdAndMenuId',data);
};