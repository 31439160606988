<template>
  <div>
    <el-table :data="tableData" border>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="成绩" align="center">
        <el-table-column label="数学">
          <el-table-column label="数学1" prop="mathScore1"></el-table-column>
          <el-table-column label="数学2" prop="mathScore2"></el-table-column>
        </el-table-column>
        <el-table-column label="语文" prop="chineseScore"></el-table-column>
      </el-table-column>
      <el-table-column label="课程" align="center">
        <el-table-column label="数学" prop="mathCourse"></el-table-column>
        <el-table-column label="语文" prop="chineseCourse"></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          id: 1,
          name: '张三',
          mathScore1: 90,
          mathScore2: 92,
          chineseScore: 85,
          mathCourse: '高数',
          chineseCourse: '文言文'
        },
        {
          id: 2,
          name: '李四',
          mathScore1: 88,
          mathScore2: 92,
          chineseScore: 92,
          mathCourse: '线性代数',
          chineseCourse: '现代文'
        }
        // 添加更多数据...
      ],
      columns: [
        { label: 'A', field: 'a', colspan: 2, rowspan: 1 },
        { label: 'B', field: 'b', colspan: 1, rowspan: 2 },
        { label: 'C', field: 'c', colspan: 1, rowspan: 2 },
        { label: 'D', field: 'd', colspan: 1, rowspan: 1 }
      ],
      items: [
        { id: 1, a: 'A1', b: 'B1', c: 'C1', d: 'D1' },
        { id: 2, a: 'A2', b: 'B2', c: 'C2', d: 'D2' }
        // ...
      ]
    }
  }
}
</script>

<style></style>
