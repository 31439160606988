import {request,post} from '@/plugins/request';

  // 查询详细
  export function getTask(data) {
    return post('/crm/v0.1/crm/crm/task/detail',data);
  }
  
  export function listTask(data) {
    return post('/crm/v0.1/crm/crm/task/list',data);
  }
  // 新增
  export function addTask(data) {
    return post('/crm/v0.1/crm/crm/task/create',data);
  }
  // 修改
  export function updateTask(data) {
    return post('/crm/v0.1/crm/crm/task/update',data);
  }
  
  // 删除
  export function delTask(data) {
    return post('/crm/v0.1/crm/crm/task/delete',data);
  }