<template>
    <div class="app-container">
        <el-row>
            <el-col>
                <el-form :model="form" ref="formt" :rules="rules"  v-loading="encryptLoading">
                    <el-form-item label="字符串" prop="password">
                        <el-input v-model="form.password" placeholder="请输入要加密的字符串" autocomplete="off"/>
                    </el-form-item>
                    <el-form-item label="企业Guid" prop="enterpriseGuid">
                        <el-input v-model="form.enterpriseGuid" placeholder="请输入要加密的字符串"/>
                    </el-form-item>
                    <el-form-item label="密码PwdKey" prop="pwdKey">
                        <el-input v-model="form.pwdKey" placeholder="请输入要加密的字符串"/>
                    </el-form-item>
                     <el-form-item label="加密结果">
                        <el-input v-model="form.pwdResult" type="textarea" />
                    </el-form-item>
                    <el-button   
                    type="primary"
                    v-preventReClick
                    @click="handleEncrypt"
                    >加密</el-button>
                </el-form>
            </el-col>
              
        </el-row>
    </div>
</template>
<script>
    import {encryptPwd} from '@/api/system/developer';
    export default {
        name: 'userPwd',
        data(){
            return{
               encryptLoading: false,
               form:{
                   password: undefined,
                   enterpriseGuid: undefined,
                   pwdKey: undefined,
                   pwdResult: undefined,
               },
               rules: {
                            password: [
                                     { required: true, message: '要加密的字符串不能为空' }
                            ],
                            enterpriseGuid: [
                                     { required: true, message: '企业Guid不能为空', trigger: 'blur' }
                            ],
                            pwdKey: [
                                     { required: true, message: 'PwdKey不能为空', trigger: 'blur' }
                            ],
               }

            }
        },
        methods:{
            
            handleEncrypt(){
                 this.$refs['formt'].validate(valid=>{
                     if(valid){
                            let data={
                            password: this.form.password,
                            enterpriseGuid: this.form.enterpriseGuid,
                            pwdKey: this.form.pwdKey,
                            }
                            encryptPwd(data).then(res=>{
                                this.form.pwdResult=res.data.result;
                            });
                    }
                 }

                 );
            }
            
        }
    }
</script>
<style>
      
</style>