import {post} from '@/plugins/request';

// 查询分页列表
export function listApplication(data) {
  return post('/rbac/v0.1/rbac/enterprise/apl/list',data);
}

// 查询详细
export function getApplication(data) {
  return post('/rbac/v0.1/rbac/enterprise/apl/detail',data);
}

// 新增
export function addApplication(data) {
  return post('/rbac/v0.1/rbac/enterprise/apl/create',data);
}

// 修改
export function updateApplication(data) {
  return post('/rbac/v0.1/rbac/enterprise/apl/update',data);
}



// 删除
export function delApplication(data) {
  return post('/rbac/v0.1/rbac/enterprise/apl/delete',data);
}


