<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          v-show="showSearch"
          :inline="true"
        >
          <el-form-item label="角色名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入角色名称关键字"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="所属企业" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
            />
          </el-form-item>
          <el-form-item label="所属系统AppId" prop="appId">
            <el-input
              v-model="queryParams.appId"
              placeholder="请输入系统AppId"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col>

          <!-- <el-col :span="1.5">
            <el-button
              type="warning"
              plain
              icon="el-icon-download"
              size="mini"
              v-preventReClick
              @click="handleExport"
             
              >导出</el-button
            >
          </el-col> -->
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>
        <el-table
          v-loading="loading"
          :data="roleList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="角色编号" prop="guid" width="240" />
          <el-table-column
            label="角色名称"
            prop="name"
            :show-overflow-tooltip="true"
            width="150"
          />
          <el-table-column
            label="所属企业标识"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
            width="150"
          />
          <el-table-column
            label="所属系统AppId"
            prop="appId"
            :show-overflow-tooltip="true"
            width="150"
          />
          <el-table-column
            label="分配用户数"
            align="center"
            width="150"
            prop="userAssignmentCount"
          />
          <el-table-column
            label="创建时间"
            align="center"
            prop="created"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-circle-check"
                v-preventReClick
                @click="handleDataScope(scope.row)"
                >数据权限</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleAuthUser(scope.row)"
                >分配用户</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.currentPage"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入角色名称" />
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input
            rows="3"
            maxlength="99"
            show-word-limit
            v-model="form.roleDesc"
            placeholder="请输入角色描述"
          />
        </el-form-item>
        <el-form-item label="所属企业标识" prop="enterpriseSecret">
          <el-input
            show-word-limit
            v-model="form.enterpriseSecret"
            placeholder="请输入所属企业标识"
          />
        </el-form-item>
        <el-form-item label="所属系统AppId" prop="appId">
          <el-input
            show-word-limit
            v-model="form.appId"
            placeholder="请输入所属系统AppId"
          />
          <el-button type="primary" v-preventReClick @click="selectChanged()">
            查询权限
          </el-button>
        </el-form-item>
        <el-form-item label="菜单权限">
          <!--<el-checkbox
            v-model="menuExpand"
            @change="handleCheckedTreeExpand($event, 'menu')"
            >展开/折叠</el-checkbox
          >
          <el-checkbox
            v-model="menuNodeAll"
            @change="handleCheckedTreeNodeAll($event, 'menu')"
            >全选/全不选</el-checkbox
          >-->
          <el-checkbox
            v-model="form.menuCheckStrictly"
            @change="handleCheckedTreeConnect($event, 'menu')"
            >父子联动</el-checkbox
          >

          <el-tree
            v-loading="menuLoading"
            class="tree-border"
            :data="menuOptions"
            show-checkbox
            ref="menu"
            node-key="guid"
            :default-checked-keys="defalultCheckMenuIds"
            :check-strictly="!form.menuCheckStrictly"
            empty-text="请先选择,所属企业和系统"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 分配用户对话框 -->
    <el-dialog
      title="分配用户"
      :visible.sync="userOpen"
      width="1000px"
      append-to-body
    >
      <el-form ref="roleForm" :model="queryUserParams" label-width="120px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="用户登录名" prop="loginName">
              <el-input v-model="queryUserParams.loginName" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="所属部门" prop="orgGuid">
              <treeselect
                v-model="queryUserParams.orgGuid"
                :options="departMent"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="请先选择企业"
                style="width: 240px"
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="7">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="queryUserParams.phone" />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                v-preventReClick
                @click="handleUserQuery"
                >搜索</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        v-loading="userLoading"
        :data="userList"
        ref="roleTable"
        @select="selectUserRowChange"
        @select-all="handleUserSelectionAll"
      >
        <el-table-column label="序号" type="index" align="center" />
        <el-table-column type="selection" width="55" />
        <el-table-column label="用户编号" align="center" prop="guid" />
        <el-table-column label="员工编号" align="center" prop="personId" />
        <el-table-column label="用户登录名称" align="center" prop="loginName" />
        <!-- <el-table-column label="手机号码" align="center" prop="phone" /> -->
        <el-table-column label="用户Email" align="center" prop="email" />
        <!-- <el-table-column label="用户名字" align="center" prop="name" /> -->
        <!-- <el-table-column label="企业标识" align="center" prop="enterpriseSecret" /> -->
        <el-table-column
          label="创建时间"
          align="center"
          prop="createTime"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.created) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="roleTotal > 0"
        :total="roleTotal"
        :page.sync="queryUserParams.currentPage"
        :limit.sync="queryUserParams.pageSize"
        @pagination="handleUserQuery"
      />
      <el-form label-width="100px">
        <el-form-item
          style="text-align: center; margin-left: -120px; margin-top: 30px"
        >
          <el-button
            type="primary"
            @click="submitUserRoleForm()"
            v-preventReClick
            >提交</el-button
          >
          <el-button @click="roleClose()">返回</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 分配角色数据权限对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="openDataScope"
      width="600px"
      append-to-body
    >
      <el-form :model="form" label-width="120px">
        <el-form-item label="角色名称">
          <el-input v-model="form.name" :disabled="true" />
        </el-form-item>
        <el-form-item label="所属企业标识" prop="enterpriseid">
          <el-input v-model="form.enterpriseSecret" :disabled="true" />
        </el-form-item>
        <el-form-item label="所属系统AppId" prop="siteId">
          <el-input v-model="form.appId" :disabled="true" />
        </el-form-item>
        <el-form-item label="数据权限">
          <!--自定义数据权限-->
          <el-button type="primary" @click="addRow" v-preventReClick
            >添加</el-button
          >

          <el-table
            :data="autoTableData"
            v-loading="dataPriLoading"
            ref="autoTable"
            border
            style="width: 100%"
          >
            <el-table-column prop="privilegeKey" label="权限标识">
              <template slot-scope="scope">
                <el-input v-model="scope.row.privilegeKey" />
              </template>
            </el-table-column>
            <el-table-column prop="privilegeGuid" label="权限值">
              <template slot-scope="scope">
                <el-input v-model="scope.row.privilegeValue" />
              </template>
            </el-table-column>
            <el-table-column label="操作" style="width: 50px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  v-preventReClick
                  @click="handleRolePriDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitDataScope" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancelDataScope">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listRole,
  getRole,
  delRole,
  addRole,
  updateRole,
  getRoleDataPri,
  batchCreateRoleDataPri
} from '@/api/system/role'
import { getMenuPriByMenuId } from '@/api/menu/index'
import {
  listUser,
  listUserByRoleGuid,
  createUserRoleByList
} from '@/api/system/user'
import { menuTreeList } from '@/api/system/menu'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'Role',
  components: { Treeselect },
  data() {
    return {
      countList: [], //统计数据
      updateFlag: false, //编辑标识
      autoTableData: [],
      orgPriFlag: true, //默认显示机构数据权限
      autoPriFlag: false, //自定义数据权限默认不显示
      oldEnterpriseid: '',
      //企业数据
      enterpriseOptions: [],
      curQuerySelectEnterpriseId: null,
      siteDefaultProps: {
        children: 'childrens',
        label: 'name'
      },
      enterpriseid: undefined,
      siteId: undefined,
      //系统站点
      siteList: [],
      //默认选中的菜单
      defalultCheckMenuIds: [],
      //默认选中的部门
      defalultCheckOrgIds: [],
      // 遮罩层
      loading: false,
      //权限菜单遮罩
      menuLoading: false,
      //用户列表遮罩
      userLoading: false,
      //数据权限遮罩
      dataPriLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      //角色总数
      roleTotal: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 分配用户弹出层
      userOpen: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      //菜单操作权限
      menuPriIds: [],
      //菜单权限
      menuIds: [],
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      defaultDataScope: '1',
      // 数据范围选项
      dataScopeOptions: [
        {
          value: '1',
          label: '组织机构数据权限'
        },
        {
          value: '2',
          label: '自定数据权限'
        }
      ],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: undefined,
        appId: undefined,
        enterpriseSecret: undefined
      },
      // 查询用户参数
      queryUserParams: {
        enterpriseGuid: undefined,
        currentPage: 1,
        pageSize: 10,
        loginName: undefined,
        phone: undefined
      },
      //所属部门
      departMent: null,
      roleGuid: null,
      // 角色用户表格数据
      userList: [],
      // 查询用户角色列表
      userRoleList: [],
      // 选中用户列表
      chooseUserList: [],
      allOrg: {},
      userGuid: null,
      siteId: null,
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'menuName'
      },
      orgDefaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ],
        enterpriseid: [
          { required: true, message: '所属企业', trigger: 'change' }
        ],
        siteId: [{ required: true, message: '所属系统', trigger: 'change' }],
        roleSort: [
          { required: true, message: '角色顺序不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getList()
    //this.getEnterpriseList();
    //this.getSiteList();
    // this.handleUserQuery();
    // this.querySelectChanged();
    //this.getCountInfo(); //角色管理统计接口
  },
  methods: {
    // 角色管理统计接口
    // getCountInfo() {
    //   this.$request({
    //     url: '/rbac/v0.1/role/countRoleBySite',
    //     method: 'post',
    //     data: JSON.stringify(this.queryParams)
    //   }).then(res => {
    //     console.log('rrreess==>', res);
    //     this.countList = res?.data?.result || [];
    //   });
    // },
    handleRolePriDelete(index, row) {
      this.autoTableData.splice(index, 1)
    },
    //添加行
    addRow() {
      var priObj = {
        privilegeKey: '',
        privilegeValue: ''
      }
      this.autoTableData.push(priObj)
    },
    delRow() {},
    //搜索区域
    querySelectChanged() {
      //debugger
      if (this.allOrg[this.queryUserParams.enterpriseid] != undefined) {
        this.departMent = this.allOrg[this.queryUserParams.enterpriseid]
      } else {
        this.departMent = null
        selectOrgTreeByEnterpriseId(this.queryUserParams).then((res) => {
          if (res.data.success) {
            this.allOrg[this.queryUserParams.enterpriseid] = res.data.result
            this.departMent = res.data.result
          } else {
            this.$msgError(res.data.message)
          }
        })
      }
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.childrens && !node.childrens.length) {
        delete node.childrens
      }
      return {
        id: node.guid,
        label: node.name,
        children: node.childrens
      }
    },
    selectChanged(value) {
      //清空菜单
      this.menuOptions = []
      var appId = this.form.appId
      var enterpriseSecret = this.form.enterpriseSecret
      if (!appId) {
        this.$msgError('请输入系统AppId')
        return
      }
      if (!enterpriseSecret) {
        this.$msgError('请输入企业标识')
        return
      }
      var params = {
        appId: appId,
        enterpriseSecret: enterpriseSecret
      }
      this.getMenuTreeselect(params)
    },
    /** 查询角色列表 */
    getList() {
      this.loading = true
      // this.getCountInfo(); // 查询条件变更时，统计数据也需要重新查询
      listRole(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.roleList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    /** 查询菜单树结构 */
    getMenuTreeselect(params) {
      this.menuLoading = true
      var appId = params.appId
      var enterpriseSecret = params.enterpriseSecret

      if (
        appId != '' &&
        appId != undefined &&
        enterpriseSecret != '' &&
        enterpriseSecret != 'undefined'
      ) {
        menuTreeList(params).then((res) => {
          if (res.data.success) {
            this.menuOptions = this.changeMenuTree(res.data.result)
            this.menuLoading = false
          } else {
            this.$msgError(res.data.message)
          }
        })
      }
    },

    changeMenuTree(tdata) {
      if (tdata != null && tdata.length > 0) {
        for (var i = 0; i < tdata.length; i++) {
          var v = tdata[i]
          if (v.childrens == null || v.childrens.length == 0) {
            if (v.menuPriList != null && v.menuPriList.length > 0) {
              var chids = []
              for (var j = 0; j < v.menuPriList.length; j++) {
                var dto = v.menuPriList[j]
                var para = {
                  guid: dto.guid,
                  menuName: dto.privilegeName,
                  menuUrl: dto.privilegeAction,
                  parentId: dto.menuGuid,
                  createTime: dto.createTime,
                  menuType: 'F'
                }
                chids.push(para)
              }
              v.childrens = chids
            }
          } else {
            this.changeMenuTree(v.childrens)
          }
        }
      }
      return tdata
    },

    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
    /** 根据角色ID查询菜单树结构 */
    getRoleMenuTreeselect(roleId) {},
    // 角色状态修改
    handleStatusChange(row) {},
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.defalultCheckMenuIds = []
      this.defalultCheckOrgIds = []
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([])
      }
      ;(this.menuExpand = false),
        (this.menuNodeAll = false),
        (this.deptExpand = true),
        (this.deptNodeAll = false),
        (this.form = {
          roleId: undefined,
          roleName: undefined,
          roleKey: undefined,
          roleSort: 0,
          status: '0',
          menuIds: [],
          menuPriIds: [],
          deptIds: [],
          siteId: undefined,
          enterpriseid: undefined,
          menuCheckStrictly: true,
          deptCheckStrictly: true,
          remark: undefined
        })
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        let treeList = this.menuOptions
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value
        }
      } else if (type == 'dept') {
        let treeList = this.deptOptions
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.dept.store.nodesMap[treeList[i].id].expanded = value
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions : [])
      } else if (type == 'dept') {
        this.$refs.dept.setCheckedNodes(value ? this.deptOptions : [])
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.form.menuCheckStrictly = value ? true : false
      } else if (type == 'dept') {
        this.form.deptCheckStrictly = value ? true : false
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.updateFlag = false
      this.reset()
      //清空菜单
      this.menuOptions = []
      //this.getMenuTreeselect();
      this.open = true
      this.title = '添加角色'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.updateFlag = true
      this.reset()
      var params = {
        guid: row.guid
      }
      var appId = row.appId
      var enterpriseSecret = row.enterpriseSecret
      var menuTreeparams = {
        appId: appId,
        enterpriseSecret: enterpriseSecret
      }
      this.getMenuTreeselect(menuTreeparams)
      getRole(params).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
          this.open = true
          this.menuIds = res.data.result.menuIds
          this.menuPriIds = res.data.result.menuPriIds

          this.defalultCheckMenuIds = this.menuIds.concat(this.menuPriIds)

          this.title = '修改角色'
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    /** 选择角色权限范围触发 */
    dataScopeSelectChange(value) {
      if (value == '1') {
        this.orgPriFlag = true
        this.autoPriFlag = false
      } else {
        this.orgPriFlag = false
        this.autoPriFlag = true
      }
    },
    /** 分配数据权限操作 */
    handleDataScope(row) {
      debugger
      this.reset()
      this.openDataScope = true
      var params = {
        roleGuid: row.guid
      }
      console.log(row)
      this.form = row
      this.autoTableData = []
      this.dataPriLoading = true
      getRoleDataPri(params).then((res) => {
        if (res.data.success) {
          this.autoTableData = res.data.result.records
          this.dataPriLoading = false
          this.title = '分配数据权限'
        } else {
          this.$msgError(res.data.message)
          this.dataPriLoading = false
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          debugger
          let checkNodes = this.$refs.menu.getCheckedNodes(false, true)
          this.form.menuIds = []
          this.form.menuPriIds = []
          checkNodes.forEach((a) => {
            if (a.menuType && a.menuType == 'F') {
              this.form.menuPriIds.push(a.guid)
            } else {
              this.form.menuIds.push(a.guid)
            }
          })

          if (this.form.guid != undefined) {
            //this.form.menuIds = this.getMenuAllCheckedKeys();
            updateRole(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            // this.form.menuIds = this.getMenuAllCheckedKeys();
            addRole(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },
    /** 提交按钮（数据权限） */
    submitDataScope: function () {
      debugger
      //判断数据权限类型
      if (this.form.guid != undefined) {
        //自定义数据权限
        debugger
        let rolePriDataList = []
        for (var j = 0; j < this.autoTableData.length; j++) {
          if (
            this.autoTableData[j].privilegeKey.trim() != '' &&
            this.autoTableData[j].privilegeValue.trim() != ''
          ) {
            var autoPriData = {
              privilegeKey: this.autoTableData[j].privilegeKey,
              privilegeValue: this.autoTableData[j].privilegeValue
            }
            rolePriDataList.push(autoPriData)
          }
        }
        //rolePriDataList.concat(this.autoTableData);
        var params = {
          roleGuid: this.form.guid,
          list: rolePriDataList
        }
        batchCreateRoleDataPri(params).then((res) => {
          if (res.data.success) {
            this.$msgSuccess('修改成功')
            this.openDataScope = false
            this.getList()
          } else {
            this.$msgError(res.data.message)
          }
        })
      }
    },
    selectSiteIdChanged(value) {
      // //debugger
      this.queryRoleForm.siteId = value
      this.$forceUpdate()
    },
    /**分配用户按钮操作 */
    handleAuthUser(row) {
      this.userOpen = true
      this.queryUserParams.enterpriseGuid = row.enterpriseGuid
      this.queryUserParams.roleGuid = row.guid
      this.roleOpen = true
      //清空
      this.userList = []
      this.chooseUserList = []
      this.roleTotal = 0
      //查询角色列表
      this.queryUserParams.enterpriseGuid = row.enterpriseGuid
      this.handleUserQuery()
      this.getUserRole()
    },
    /** 搜索按钮操作 */
    async handleUserQuery() {
      this.userLoading = true
      const res = await listUser(this.queryUserParams)
      this.userList = res.data.result.records
      this.roleTotal = res.data.result.total
      //回显
      this.userLoading = false
      this.echo(this.chooseUserList)
    },
    roleClose() {
      this.userOpen = false
    },
    //用户角色提交按钮
    submitUserRoleForm() {
      var userRoleList = []
      var param = {
        list: this.chooseUserList,
        roleGuid: this.queryUserParams.roleGuid
      }
      createUserRoleByList(param).then((res) => {
        if (res.data.success) {
          this.$msgSuccess('新增成功')
          this.userOpen = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //用户的复选
    selectUserRowChange(selection, row) {
      if (selection.length && selection.indexOf(row) !== -1) {
        //说明是选中
        var userRole = {
          guid: '',
          roleGuid: this.queryUserParams.roleGuid,
          userGuid: row.guid
        }
        this.chooseUserList.push(userRole)
      } else {
        //取消
        for (var i = 0; i < this.chooseUserList.length; i++) {
          if (this.chooseUserList[i].userGuid == row.guid) {
            this.chooseUserList.splice(i, 1)
          }
        }
      }
    },
    // 用户全选操作
    handleUserSelectionAll(selection) {
      // //debugger
      if (selection.length > 0) {
        //说明是全选
        for (var i = 0; i < selection.length; i++) {
          var flag = false
          for (var j = 0; j < this.chooseUserList.length; j++) {
            if (selection[i].guid == this.chooseUserList[j].userGuid) {
              flag = true
            }
          }
          if (!flag) {
            var userRole = {
              guid: '',
              roleGuid: this.queryUserParams.roleGuid,
              userGuid: selection[i].guid
            }
            this.chooseUserList.push(userRole)
          }
        }
      } else {
        //取消全选
        for (var i = 0; i < this.userList.length; i++) {
          var flag = false
          for (var j = 0; j < this.chooseUserList.length; j++) {
            if (this.userList[i].guid == this.chooseUserList[j].userGuid) {
              this.chooseUserList.splice(j, 1)
              break
            }
          }
        }
      }
    },
    getUserRole() {
      //根据角色查询用户表
      var params = {
        roleGuid: this.queryUserParams.roleGuid
      }
      debugger
      listUserByRoleGuid(params).then((res) => {
        if (res.data.success) {
          this.userRoleList = res.data.result.records
          for (var i = 0; i < this.userRoleList.length; i++) {
            var userRole = {
              guid: '',
              roleGuid: this.userRoleList[i].roleGuid,
              userGuid: this.userRoleList[i].userGuid
            }
            this.chooseUserList.push(userRole)
          }
          this.echo(this.userRoleList)
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //回显
    echo(selectData) {
      selectData.forEach((item) => {
        this.userList.forEach((row) => {
          if (item.userGuid == row.guid) {
            this.$nextTick(() => {
              this.$refs.roleTable.toggleRowSelection(row, true)
            })
          }
        })
      })
    },

    /** 所属部门下拉框 */
    // departMent
    // async getdepartMentList(){
    //   const res = await getAllListByEnterpriseId({});

    // }
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除角色名称为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delRole(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams
      this.$confirm('是否确认导出所有角色数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          return exportRole(queryParams)
        })
        .then((res) => {
          if (res.data.success) {
            this.$download(res.msg)
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
