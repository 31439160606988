import {request,post} from '@/plugins/request';

// 查询分页列表
export function listEnterprise(data) {
  return post('/rbac/v0.1/rbac/enterprise/et/list',data);
}

// 查询详细
export function getEnterprise(data) {
  return post('/rbac/v0.1/rbac/enterprise/et/detail',data);
}

// 新增
export function addEnterprise(data) {
  return post('/rbac/v0.1/rbac/enterprise/et/create',data);
}

// 修改
export function updateEnterprise(data) {
  return post('/rbac/v0.1/rbac/enterprise/et/update',data);
}



// 删除
export function delEnterprise(data) {
  return post('/rbac/v0.1/rbac/enterprise/et/delete',data);
}


