import { render, staticRenderFns } from "./editTasks.vue?vue&type=template&id=39074817&scoped=true"
import script from "./editTasks.vue?vue&type=script&lang=js"
export * from "./editTasks.vue?vue&type=script&lang=js"
import style0 from "./editTasks.vue?vue&type=style&index=0&id=39074817&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39074817",
  null
  
)

export default component.exports