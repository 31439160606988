<template>
  <div>
    <div class="search">
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        v-show="showSearch"
        label-width="90px"
      >
        <el-form-item label="联系人名称" prop="contactName">
          <el-input
            v-model="queryParams.contactName"
            placeholder="请输入关键词"
            clearable
            size="small"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            v-preventReClick
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="list">
      <el-table border v-loading="loading" :data="dataList">
        <el-table-column
          label="待办类型"
          min-width="100px"
          key="taskCategoryName"
          prop="taskCategoryName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="待办内容"
          min-width="300px"
          key="taskContent"
          prop="taskContent"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-html="$parseText(scope.row.taskContent)"></div>
          </template>
        </el-table-column>
        <el-table-column
          label="待办时间"
          min-width="160px"
          key="taskTime"
          prop="taskTime"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="关联商机"
          min-width="200px"
          key="opportunityInfo.opportunityName"
          prop="opportunityInfo.opportunityName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="关联联系人"
          min-width="100px"
          key="contactName"
          prop="contactName"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="创建时间" prop="createTime" min-width="160">
          <template slot-scope="scope">
            <span>{{ $parseTime(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          min-width="130"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="text" icon="el-icon-edit" v-preventReClick
                @click="handleView(scope.row)">查看</el-button> -->
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              v-preventReClick
              @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              v-preventReClick
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.currentPage"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <el-divider></el-divider>
    <div style="text-align: center">
      <el-button @click="addTask" type="primary">写待办</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
      v-if="open"
    >
      <editTasks
        :customer_guid="myValue"
        v-model="taskEntity.guid"
        @editResult="editResult"
      ></editTasks>
    </el-dialog>
  </div>
</template>

<script>
import { listTask, delTask } from '@/api/crm/task'
import editTasks from '@/views/system/crm/common/editTasks.vue'
export default {
  name: 'CustomerTasks',
  data() {
    return {
      open: false,
      title: '',
      myValue: this.value,
      loading: false,
      dataList: [],
      total: 0,
      taskEntity: {},
      drawer: {
        title: '',
        visible: false,
        customer: {}
      },
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 5,
        contactName: undefined,
        customerGuid: this.value
      },
      showSearch: false
    }
  },
  components: {
    editTasks
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    //新增联系人
    addTask() {
      this.taskEntity = []
      this.title = '写待办'
      this.open = true
    },
    editResult(v) {
      this.open = false
      this.getList()
    },
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    resetQuery() {
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    getList() {
      this.loading = true
      listTask(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    handleView(row) {
      this.contactEntity = row
      this.drawer.visible = true
    },
    handleUpdate(row) {
      this.open = true
      this.title = '修改待办'
      this.taskEntity = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delTask(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
      this.queryParams.customerGuid = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
.el-drawer__header {
  margin-bottom: 10px;
}

.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}

.search {
  text-align: center;
}

ul,
li {
  list-style-type: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

li {
  line-height: 30px;

  .title {
    width: 80px;
    text-align: right;
  }
}

span {
  font-size: 14px;
  color: #72767b;
  display: inline-block;
}
</style>
