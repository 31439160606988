import { render, staticRenderFns } from "./opportunites.vue?vue&type=template&id=af54cfa0&scoped=true"
import script from "./opportunites.vue?vue&type=script&lang=js"
export * from "./opportunites.vue?vue&type=script&lang=js"
import style0 from "./opportunites.vue?vue&type=style&index=0&id=af54cfa0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af54cfa0",
  null
  
)

export default component.exports