<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      v-loading="loading"
      label-width="120px"
    >
      <el-row>
        <el-col>
          <el-form-item label="客户名称" prop="customerName">
            <el-input
              v-model="form.customerName"
              placeholder="请输入客户名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="跟进状态" prop="customerStatus">
            <maindata
              v-model="form.customerStatus"
              data-type="singleSelect"
              data-key="crmCustomerStatus"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="客户来源" prop="customerSource">
            <maindata
              v-model="form.customerSource"
              data-type="singleSelect"
              data-key="crmCustomerSource"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="客户等级" prop="customerDegree">
            <maindata
              v-model="form.customerDegree"
              data-type="singleSelect"
              data-key="crmCustomerDegree"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="所属行业" prop="customerIndustry">
            <maindata
              v-model="form.customerIndustry"
              data-type="checkbox"
              data-key="crmCustomerIndustry"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="企业类型" prop="customerCategory">
            <maindata
              v-model="form.customerCategory"
              data-type="singleSelect"
              data-key="crmCustomerCategory"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="意向/采购产品" prop="purchaseInfo">
            <maindata
              v-model="form.purchaseInfo"
              data-type="checkbox"
              data-key="crmCustomerPurchaseIdea"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col>
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="form.companyName" placeholder="请输入公司名称" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="公司规模" prop="companySize">
            <maindata
              v-model="form.companySize"
              data-type="singleSelect"
              data-key="crmCustomerSize"
            ></maindata>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="注册资本" prop="companyRegisteredCapital">
            <el-input
              v-model="form.companyRegisteredCapital"
              placeholder="请输入公司注册资本"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="开票信息" prop="invoicingInfo">
            <el-input
              type="textarea"
              v-model="form.invoicingInfo"
              placeholder="请输入开票信息"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="公司地址" prop="companyAddress">
            <el-input
              v-model="form.companyAddress"
              placeholder="请输入公司地址"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="公司资质" prop="companyQualifactions">
            <ImageUpload
              v-model="form.companyQualifactions"
              @syncFileIds="savecompanyQualifactions"
            ></ImageUpload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="备注" prop="comment">
            <el-input type="textarea" v-model="form.comment" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm" v-preventReClick
        >确 定</el-button
      >
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import { addCustomer, getCustomer, updateCustomer } from '@/api/crm/customer'
import maindata from '@/components/MainData/index.vue'
import ImageUpload from '@/components/ImageUpload/indexNew.vue'
export default {
  name: 'EditCustomer',
  components: {
    maindata,
    ImageUpload
  },
  data() {
    return {
      loading: false,
      myValue: this.value,
      form: {},
      // 表单校验
      rules: {
        customerName: [
          { required: true, message: '客户名称不能为空', trigger: 'blur' }
        ],
        customerSource: [
          { required: true, message: '客户来源不能为空', trigger: 'blur' }
        ],
        customerDegree: [
          { required: true, message: '客户等级不能为空', trigger: 'blur' }
        ],
        customerCategory: [
          { required: true, message: '客户类型不能为空', trigger: 'blur' }
        ],
        customerIndustry: [
          { required: true, message: '所属行业不能为空', trigger: 'blur' }
        ],
        customerStatus: [
          { required: true, message: '跟进状态不能为空', trigger: 'blur' }
        ],
        purchaseInfo: [
          { required: true, message: '意向采购产品不能为空', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  created() {},
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      this.reset()
      if (!this.myValue) {
        this.reset()
        return
      }
      this.loading = true
      getCustomer({
        guid: this.myValue
      }).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
        } else {
          console.log(res.data.message)
        }
        this.loading = false
      })
    },
    // 取消按钮
    cancel() {
      this.$emit('editResult', 'close')
    },
    // 表单重置
    reset() {
      this.form = {}
      this.$resetForm('form')
    },
    savecompanyQualifactions(v) {
      this.form.companyQualifactions = v
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form)
          if (this.form.guid !== undefined) {
            updateCustomer(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.$emit('editResult', 'update')
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addCustomer(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.$emit('editResult', 'add')
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
      this.initForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: center;
}
</style>
