<template>
  <div>
    <div class="viewInfoContent">
      <ul>
        <li>
          <span class="title">客户名称：</span
          ><span class="content">{{ contact.customerInfo.customerName }}</span>
        </li>
        <li>
          <span class="title">公司名称：</span
          ><span class="content">{{ contact.customerInfo.companyName }}</span>
        </li>
      </ul>
      <el-divider><span style="font-size: 12px">联系人信息</span></el-divider>
      <ul>
        <li>
          <span class="title">姓名：</span
          ><span class="content">{{ contact.contactName }}</span>
        </li>
        <li>
          <span class="title">职务：</span
          ><span class="content">{{ contact.contactJob }}</span>
        </li>
        <li>
          <span class="title">手机：</span
          ><span class="content">{{ contact.contactTelephone }}</span>
        </li>
        <li>
          <span class="title">办公电话：</span
          ><span class="content">{{ contact.contactOfficePhone }}</span>
        </li>
        <li>
          <span class="title">Email：</span
          ><span class="content">{{ contact.contactEmail }}</span>
        </li>
        <li>
          <span class="title">微信：</span
          ><span class="content">{{ contact.contactWx }}</span>
        </li>
        <li>
          <span class="title">QQ：</span
          ><span class="content">{{ contact.contactQq }}</span>
        </li>
        <li>
          <span class="title">地址：</span
          ><span class="content">{{ contact.contactAddress }}</span>
        </li>
        <li>
          <span class="title">备注：</span
          ><span class="content" v-html="$parseText(contact.comment)"></span>
        </li>
      </ul>
    </div>
    <!-- <el-divider></el-divider>
  <div style="text-align: center;"> <el-button @click="editContact" type="primary">修改联系人</el-button></div> -->
  </div>
</template>
<script>
export default {
  name: 'ViewContact',
  data() {
    return {
      contact: this.value,
      myValue: this.value
    }
  },
  components: {},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mounted() {},
  methods: {
    editContact() {
      this.$emit('editContact', this.contact)
    }
  },
  watch: {
    value(newValue) {
      this.contact = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.viewInfoContent {
  // display: grid;
  ul,
  li {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  li {
    line-height: 28px;
    margin-bottom: 1px;
    .title {
      width: 20%;
      text-align: right;
      vertical-align: top;
    }
    .content {
      width: 80%;
      background-color: #f5f5f5;
      padding: 0 5px 0 5px;
    }
  }
  span {
    font-size: 14px;
    color: #72767b;
    display: inline-block;
  }
}
</style>
