<template>
  <div class="app-container">
    <div class="top_box">
      <ImageUpload :fileIds="attachments" :pageType="pageType" @syncFileIds="saveFileIds"></ImageUpload>

    </div>
    <div ><img src="/static/20230512101851.jpg" id="testImg" ref='tstImg'></div>
    <div><canvas ref="canvas" @mousedown="startSelection" @mousemove="drawSelection" @mouseup="endSelection"></canvas></div>
    <button @click="saveCanvas">保存为PNG</button>
    <div class="showarea" v-if="messages">
      <p style="padding-bottom: 20px;text-align: center;color: #1890ff;">以下为本图片识别出的内容</p>
      {{ messages }}
    </div>
  </div>
</template>
<script>
import { ocrTranscription } from '@/api/ai/ocr'
import ImageUpload from '@/components/ImageUpload/index'
export default {
  name: 'AiChat',
  data() {
    return {
      attachments: [],
      messages: '',
      pageType: 'ocr'
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.context = this.canvas.getContext('2d');

    var img=this.$refs.tstImg;
    this.canvas.width=512;
    this.canvas.height=512;
    this.context.drawImage(img,0,0,512,512);
    this.isSelecting = false;
    this.selectionRect = {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    }; },
  methods: {
    startSelection(event) {
      const { offsetX, offsetY } = event;
      this.isSelecting = true;
      this.selectionRect.startX = offsetX;
      this.selectionRect.startY = offsetY;
    },
    drawSelection(event) {
      if (!this.isSelecting) return;
      const { offsetX, offsetY } = event;
      this.selectionRect.endX = offsetX;
      this.selectionRect.endY = offsetY;
      this.draw();
    },
    endSelection() {
      this.isSelecting = false;
      this.draw();
    },
    draw() {
      const { startX, startY, endX, endY } = this.selectionRect;
      const canvas = this.canvas;
      const context = this.context;

      // 清除画布
      context.clearRect(0, 0, canvas.width, canvas.height);

      // 绘制原始图像
      const image = new Image();
      image.src = '/static/20230512101851.jpg';
      image.onload = () => {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        
        // 绘制圈选区域并设置透明度
        context.fillStyle = 'rgba(0, 0, 0, 0.5)';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.clearRect(startX, startY, endX - startX, endY - startY);
      };
    },
    saveCanvas() {
      const canvas = this.$refs.canvas;
      const dataURL = canvas.toDataURL("image/png"); // 将canvas转换为PNG数据URL
      
      // 创建链接元素
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "canvas_image.png"; // 设置文件名
      
      // 模拟点击链接以触发下载
      link.click();
    },
    saveFileIds(ids) {
      this.attachments = ids
      ocrTranscription({
        guid: ids.toString(),
        language: 'ch'
      }).then((res) => {
        if (res.data.success) {
          console.log(JSON.parse(res.data.result))
          this.messages = JSON.parse(res.data.result).result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
  },
  components: {
    ImageUpload // 注册上传组建
  }
}
</script>
<style scoped>
canvas {
  max-width: 100%;
  height: auto;
  border: 1px solid #000;
}
p {
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.top_box {
  margin: 10px 0;
  border-bottom: 1px solid #DCDFE6;
  padding-bottom: 10px;
}

.showarea {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 20px;
  line-height: 24px;
  background: #f8f8f8;
}

@media only screen and (max-width: 768px) {
  [class*="top_box"] {
    width: 100%;
    background: #fff;
  }

  [class*="showarea"] {
    width: 100%;
  }
}
</style>
