<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="loading">
          <el-row>
            <el-col>
              <el-form-item label="姓名" prop="contactName">
                <el-input  v-model="form.contactName" placeholder="请输入联系人名称"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="职务" prop="contactJob">
                <el-input  v-model="form.contactJob" placeholder="请输入联系人职务"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="手机" prop="contactTelephone">
                <el-input  v-model="form.contactTelephone" placeholder="请输入联系人手机"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="办公电话" prop="contactOfficePhone" >
                <el-input  v-model="form.contactOfficePhone" placeholder="请输入联系人办公电话"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="Email" prop="contactEmail" >
                <el-input  v-model="form.contactEmail" placeholder="请输入联系人电子邮箱地址"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="微信" prop="contactWx" >
                <el-input  v-model="form.contactWx" placeholder="请输入联系人微信号"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="QQ" prop="contactQq" >
                <el-input  v-model="form.contactQq" placeholder="请输入联系人QQ号"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="地址" prop="contactAddress" >
                <el-input  v-model="form.contactAddress" placeholder="请输入联系人地址"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注" prop="comment">
                <el-input type="textarea" v-model="form.comment" />
              </el-form-item>
            </el-col>
          </el-row>
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm" v-preventReClick
            >确 定</el-button
          >
          <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {getContact,listContact,delContact,addContact,updateContact} from '@/api/crm/contact'
export default {
    name:'editContact',
    data(){
        return {
           loading:false,
           form:{},
           myValue:this.value,
           customerGuid:this.customer_guid,
           // 表单校验
            rules: {
                contactName: [
                    { required: true, message: '联系人名称不能为空', trigger: 'blur' }
                ],
                contactJob: [
                    { required: true, message: '职务不能为空', trigger: 'blur' }
                ],
                contactTelephone: [
                    { required: true, message: '联系人手机不能为空', trigger: 'blur' },
                    {
                      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                      message: "请输入正确的手机号码",
                      trigger: "blur",
                    }
                ]
            }
        }
    },
    components:{

    },
    props:{
        value:{
            type:String,
            default:''
        },
        customer_guid:{
            type:String,
            default:''
        }
    },
    created(){

    },
    mounted(){
        this.initForm()
    },
    methods:{
        // 表单重置
        reset() {
            this.form = {}
            this.$resetForm('form')
        },
        initForm(){
            this.reset();
           if(!this.myValue){
             this.reset();
             return;
           };
           this.loading=true;
            getContact({
                guid:this.myValue
            }).then(res=>{
                if(res.data.success){
                    this.form=res.data.result;
                }else{
                    console.log(res.data.message);
                }
                this.loading=false;
            })
        },
        cancel(){
            this.$emit("editResult","close")
        },
        /** 提交按钮 */
      submitForm: function () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.customerGuid=this.customerGuid;
            console.log(this.form)
            if (this.form.guid != undefined) {
              updateContact(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('修改成功')
                  this.$emit("editResult","update")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            } else { 
              addContact(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('新增成功')
                  this.$emit("editResult","add")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            }
          }
        })
       }
    },
    watch:{
       value(newValue){
        this.myValue=newValue;
        this.initForm();
       }
    }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
    text-align: center;
}
</style>