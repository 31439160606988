import {request,post} from '@/plugins/request';

  // 查询详细
  export function getContact(data) {
    return post('/crm/v0.1/crm/crm/contact/detail',data);
  }
  
  export function listContact(data) {
    return post('/crm/v0.1/crm/crm/contact/list',data);
  }
  // 新增
  export function addContact(data) {
    return post('/crm/v0.1/crm/crm/contact/create',data);
  }
  // 修改
  export function updateContact(data) {
    return post('/crm/v0.1/crm/crm/contact/update',data);
  }
  
  // 删除
  export function delContact(data) {
    return post('/crm/v0.1/crm/crm/contact/delete',data);
  }