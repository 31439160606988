import {request,post} from '@/plugins/request';

  // 查询详细
  export function getPurchase(data) {
    return post('/crm/v0.1/crm/crm/purchase/detail',data);
  }
  
  export function listPurchase(data) {
    return post('/crm/v0.1/crm/crm/purchase/list',data);
  }
  // 新增
  export function addPurchase(data) {
    return post('/crm/v0.1/crm/crm/purchase/create',data);
  }
  // 修改
  export function updatePurchase(data) {
    return post('/crm/v0.1/crm/crm/purchase/update',data);
  }
  
  // 删除
  export function delPurchase(data) {
    return post('/crm/v0.1/crm/crm/purchase/delete',data);
  }