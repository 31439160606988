<template>
  <div class="app-container  page-box-common" v-loading="loading">
    <div slot="header" class="clearfix header-title"><span>{{title}}</span></div>
     <el-form ref="form" :model="form" :rules="rules" label-width="80px" >
        
         <el-row>
          <el-col >
            <el-form-item label="分类" prop="categoryGuid" >
              <el-select v-model="form.categoryGuid" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.guid"
                  :label="dict.categoryName"
                  :value="dict.guid"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="标题" prop="title">
              <el-input  style="width: 800px" v-model="form.title" placeholder="请输入标题"   />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="副标题" prop="subtitle">
              <el-input style="width: 800px" v-model="form.subtitle" type="textarea" placeholder="请输入副标题"   />
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col >
            <el-form-item label="内容" prop="content" >
            <tinymce v-model="form.content" placeholder="请输入内容简介" style="width: 800px;margin-bottom: 20px;"></tinymce> 
             
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col >
            <el-form-item label="附件" prop="attachments" >
            <div style="width:800px;"><ImageUpload :fileIds="attachments" @syncFileIds="saveFileIds"></ImageUpload></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="来源" prop="source">
              <el-input  style="width: 800px" v-model="form.source" placeholder="请输入文章来源"   />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
  </div>
</template>
<script>
import {
  listKupinCategory
} from '@/api/kupin/category';
import tinymce from '@/components/mce/tinymce.vue';
import ImageUpload from '@/components/ImageUpload/index'
import {
  listKupin,
  getKupin,
  addKupin,
  updateKupin,
  delKupin
} from '@/api/kupin/content'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'kupinEdit',
   components: {
     tinymce,//文本编辑器组件
     ImageUpload //注册上传组件
  },
  data() {
    return {
      attachments:[],
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '增加内容',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      //分类列表
      categoryOptions:[],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        title: undefined,
        category: undefined
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        title: [
          { required: true, message: '系统名称不能为空', trigger: 'blur' }
        ],
        categoryGuid: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '文章来源不能为空', trigger: 'blur' }
        ]
        
      }
    }
  },
  created() {
    
    this.initialize();
  },
  methods: {
    initialize(){
        this.getRolePriList();
        this.getCategoryList();
        const guid = this.$urlParse.getUrlKey('guid');
        if(guid){
          this.getContent(guid);
        }else{
            this.loading=false;
        }
    },
    /** 查询角色列表 */
    getRolePriList() {
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取分类
    getCategoryList(){
        var data = {};
        listKupinCategory(data).then((res) => {
            if (res.data.success) {
               this.categoryOptions=res.data.result.records;
            } else {
                this.$msgError(res.data.message)
            }
        })
    },
    // 取消按钮
    cancel() {
       this.$router.go(-1);
    },
    // 表单重置
    reset() {
      this.$resetForm('form')
    },
    /** 修改按钮操作 */
    getContent(guid) {
      this.reset()
      var data = {
        guid: guid
      }
      getKupin(data).then((res) => {
        if (res.data.success) {
          this.form = res.data.result;
          if(res.data.result.attachment){
            this.attachments=res.data.result.attachment.split('`');
          }
          this.title = '修改内容'
        } else {
          this.$msgError(res.data.message)
        }
        this.loading=false;
      })
    },
    saveFileIds(ids){
      this.attachments=ids;
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.attachments&&this.attachments.length>0){
            this.form.attachment=this.attachments.join('`');
          }else{
             this.form.attachment='';
          }
          if (this.form.guid != undefined) {
            updateKupin(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.$router.go(-1);
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addKupin(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.$router.push('/system/kupin/content')
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

  }
}
</script>
<style scoped>

.dialog-footer{
    margin: 0 0 0 80px;
}
.header-title {
  padding: 18px 0px;
  border-bottom: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
  box-sizing: border-box;
  margin-bottom: 18px;
}
</style>