<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="loading">
          <el-row>
            <el-col>
              <el-form-item label="产品名称" prop="purchaseProductName">
                <el-input  v-model="form.purchaseProductName" placeholder="请输入采购产品名称"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="产品类目" prop="purchaseProductCategory">
                <maindata v-model="form.purchaseProductCategory" dataType="checkbox" dataKey="crmCustomerPurchaseIdea">
                        </maindata>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="采购日期" prop="purchaseDate">
                <el-date-picker
                        v-model="form.purchaseDate"
                        type="date"
                        placeholder="选择日期">
                        </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="产品单价(元)" prop="purchaseProductPrice">
                <el-input  v-model="form.purchaseProductPrice" placeholder="请输入产品价格，单位元" @blur="computeTotal"  />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="产品数量" prop="purchaseProductNum">
                <el-input  v-model="form.purchaseProductNum" placeholder="请输入采购产品数量" @blur="computeTotal"  />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="产品总价(元)" prop="purchaseProductTotal">
                <el-input  v-model="form.purchaseProductTotal" readonly=""/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="产品配置" prop="purchaseProductDesc" >
                <el-input  v-model="form.purchaseProductDesc" type="textarea" placeholder="请输入产品配置信息"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="收货信息" prop="deliverInfo" >
                <el-input  v-model="form.deliverInfo" type="textarea" placeholder="请输入收货信息"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col >
              <el-form-item label="发票信息" prop="invoiceInfo" >
                <el-input  v-model="form.invoiceInfo" type="textarea" placeholder="请输入发票信息"   />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注" prop="comment">
                <el-input type="textarea" v-model="form.comment" />
              </el-form-item>
            </el-col>
          </el-row>
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm" v-preventReClick
            >确 定</el-button
          >
          <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {getPurchase,listPurchase,delPurchase,addPurchase,updatePurchase} from '@/api/crm/purchase'
import maindata from '@/components/MainData/index.vue'
export default {
    name:'editPurchase',
    data(){
        return {
           loading:false,
           form:{},
           myValue:this.value,
           customerGuid:this.customer_guid,
           // 表单校验
            rules: {
                purchaseProductName: [
                    { required: true, message: '产品名称不能为空', trigger: 'blur' }
                ],
                purchaseProductCategory: [
                    { required: true, message: '所属类名不能为空', trigger: 'blur' }
                ],
                purchaseProductPrice: [
                    { required: true, message: '产品价格不能为空', trigger: 'blur' },
                    {
                        pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/,
                        message: "请输入合法的金额数字，最多两位小数",
                        trigger: "blur"
                    }

                ],
                purchaseProductDesc: [
                    { required: true, message: '产品配置不能为空', trigger: 'blur' }
                ],
                purchaseDate: [
                    { required: true, message: '采购日期不能为空', trigger: 'blur' }
                ],
                purchaseProductNum:[
                    { required: true, message: '产品数量不能为空', trigger: 'blur' },
                    {
                        pattern:/^[1-9]\d*$/,
                        message: "请输入合法的数量",
                        trigger: "blur"
                    }
                ],
                purchaseProductTotal: [
                    { required: true, message: '产品总价格不能为空', trigger: 'blur' },
                    {
                        pattern: /^(([1-9]{1}\d{0,9})|(0{1}))(\.\d{1,2})?$/,
                        message: "请输入合法的金额数字，最多两位小数",
                        trigger: "blur"
                    }
                ],
                deliverInfo: [
                    { required: true, message: '收货信息不能为空', trigger: 'blur' }
                ],
                invoiceInfo:[
                    { required: true, message: '发票信息不能为空', trigger: 'blur' }
                ]

            }
        }
    },
    components:{
        maindata
    },
    props:{
        value:{
            type:String,
            default:''
        },
        customer_guid:{
            type:String,
            default:''
        }
    },
    created(){

    },
    mounted(){
        this.initForm()
    },
    methods:{
        // 表单重置
        reset() {
            this.form = {}
            this.$resetForm('form')
        },
        initForm(){
            this.reset();
           if(!this.myValue){
             this.reset();
             return;
           };
           this.loading=true;
            getPurchase({
                guid:this.myValue
            }).then(res=>{
                if(res.data.success){
                    this.form=res.data.result;
                }else{
                    console.log(res.data.message);
                }
                this.loading=false;
            })
        },
        cancel(){
            this.$emit("editResult","close")
        },
        computeTotal(){
              if(!this.form.purchaseProductPrice||!this.form.purchaseProductNum)return;
              this.form.purchaseProductTotal=parseFloat(this.form.purchaseProductPrice*this.form.purchaseProductNum).toFixed(2);
        },
        /** 提交按钮 */
      submitForm: function () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.customerGuid=this.customerGuid;
            console.log(this.form)
            if (this.form.guid != undefined) {
              updatePurchase(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('修改成功')
                  this.$emit("editResult","update")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            } else { 
              addPurchase(this.form).then((res) => {
                if (res.data.success) {
                  this.$msgSuccess('新增成功')
                  this.$emit("editResult","add")
                } else {
                  this.$msgError(res.data.message)
                }
              })
            }
          }
        })
       }
    },
    watch:{
       value(newValue){
        this.myValue=newValue;
        this.initForm();
       }
    }
}
</script>

<style lang="scss" scoped>
.dialog-footer{
    text-align: center;
}
</style>