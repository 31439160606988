<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
        >
          <el-form-item label="部门名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入部门名称"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="所属企业" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              v-preventReClick
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd()"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="deptList"
          row-key="guid"
          :tree-props="{ children: 'childrens', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            prop="name"
            label="部门名称"
            width="260"
          ></el-table-column>
          <el-table-column
            prop="orgRank"
            label="排序"
            width="200"
          ></el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
                v-preventReClick
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-plus"
                @click="handleAdd(scope.row)"
                v-preventReClick
                >新增</el-button
              >
              <el-button
                v-if="scope.row.parentId != 0"
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                v-preventReClick
                >删除</el-button
              >
              <!-- <el-button
                v-if="scope.row.status === 1"
                size="mini"
                type="text"
                @click="setRoleOpen(scope.row)"
                v-preventReClick
                >设置默认角色</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 添加或修改部门对话框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="600px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属企业" prop="enterpriseSecret">
              <el-input
                v-model="form.enterpriseSecret"
                placeholder="请输入企业标识"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.parentGuid !== 0">
            <el-form-item label="上级部门" prop="parentGuid">
              <treeselect
                v-model="form.parentGuid"
                :options="deptOptions"
                :normalizer="normalizer"
                placeholder="请先选择所属企业"
                @select="handleTreeSelect"
                @deselect="handleDeSelect"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入部门名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="部门简称" prop="shortName">
              <el-input v-model="form.shortName" placeholder="请输入部门名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="英文名称" prop="nameEn">
              <el-input v-model="form.nameEn" placeholder="请输入部门名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orgRank">
              <el-input-number
                v-model="form.orgRank"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="部门描述" prop="orgDescription">
              <el-input type="textarea" v-model="form.orgDescription" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-preventReClick
          >确 定</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMenuPriByMenuId } from '@/api/menu/index'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  listOrgTree,
  listAllUser,
  getOrg,
  delOrg,
  addOrg,
  updateOrg
} from '@/api/system/org'
export default {
  name: 'org',
  components: { Treeselect },
  data() {
    return {
      title: '',
      open: false,
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      menuPriPath: [],
      // 查询参数
      queryParams: {
        menuName: undefined,
        visible: undefined,
        appId: undefined,
        enterpriseSecret: undefined
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表格树数据
      deptList: [],
      // 部门树选项
      deptOptions: [],
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        parentId: [
          { required: true, message: '上级部门不能为空', trigger: 'blur' }
        ],
        enterpriseid: [
          { required: true, message: '所属企业不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '显示排序不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.handleQuery()
  },
  methods: {
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    getList() {
      this.loading = true
      listOrgTree(this.queryParams).then((res) => {
        //数据转换
        if (res.data.success) {
          this.deptList = res.data.result
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {
        name: undefined,
        shortName: undefined,
        nameEn: undefined,
        orgRank: undefined,
        orgDescription: undefined,
        parentGuid: undefined,
        orgLevel: undefined,
        enterpriseid: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      debugger
      this.reset()
      this.deptOptions = null
      if (row != undefined) {
        this.form.parentGuid = row.guid
        this.form.orgLevel = row.orgLevel
        var params = {
          enterpriseSecret: this.form.enterpriseSecret
        }
        listOrgTree(params).then((res) => {
          if (res.data.success) {
            this.deptOptions = res.data.result
          } else {
            this.$msgError(res.data.message)
          }
        })
      }
      this.open = true
      this.title = '添加部门'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      // //debugger
      this.reset()
      this.deptOptions = null
      var params = {
        guid: row.guid
      }
      getOrg(params).then((res) => {
        //debugger
        this.form = res.data.result
        this.open = true
        this.title = '修改部门'

        var params = {
          enterpriseSecret: this.form.enterpriseSecret
        }
        listOrgTree(params).then((response) => {
          if (response.data.success) {
            this.deptOptions = response.data.result
          } else {
            this.$msgError(response.data.message)
          }
        })
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      // //debugger
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.orgLevel == '' || this.form.orgLevel == undefined) {
            this.form.orgLevel = 1
          }
          if (this.form.guid != undefined) {
            updateOrg(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addOrg(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function () {
          var params1 = {
            guid: row.guid
          }
          return delOrg(params1)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    },
    handleTreeSelect(node, instanceId) {
      console.log('Select')
      if (node.orgLevel != undefined && node.orgLevel != null) {
        this.form.orgLevel = parseInt(node.orgLevel) + 1
      }
    },
    handleDeSelect(node, instanceId) {
      this.form.orgLevel = ''
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      if (node.childrens && !node.childrens.length) {
        delete node.childrens
      }
      return {
        id: node.guid,
        label: node.name,
        children: node.childrens
      }
    }
  }
}
</script>

<style>
</style>