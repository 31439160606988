<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="120px"
        >
          <el-form-item label="流程定义名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入流程定义名称关键字"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="流程定义Key" prop="key">
            <el-input
              v-model="queryParams.key"
              placeholder="请输入流程定义Key"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <!-- <el-form-item label="流程分类" prop="category" >
              <el-select v-model="queryParams.category" placeholder="请选择">
                <el-option
                  v-for="dict in categoryOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                ></el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item label="企业标识" prop="enterpriseSecret">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <!-- <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              >新增</el-button
            >
          </el-col> -->
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="processDefineList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="ID"
            align="center"
            key="id"
            prop="id"
            width="120"
          />
          <el-table-column
            label="名称"
            align="center"
            key="name"
            prop="name"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="Key"
            align="center"
            key="key"
            prop="key"
            :show-overflow-tooltip="true"
          />
          <!-- <el-table-column
            label="流程分类"
            align="center"
            prop="category"
          >
          <template slot-scope="scope">
              <span>{{ $selectDictLabel(categoryOptions,scope.row.category) }}</span>
            </template>
        </el-table-column> -->
          <el-table-column
            label="企业标识"
            align="center"
            key="enterpriseSecret"
            prop="enterpriseSecret"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="流程定义版本号"
            align="center"
            key="version"
            prop="version"
            width="120"
          />
          <el-table-column
            label="部署ID"
            align="center"
            key="deploymentId"
            prop="deploymentId"
            width="120"
          />
          <el-table-column
            label="状态"
            align="center"
            prop="isSuspended"
            width="120"
          >
            <template slot-scope="scope">
              <span>{{ getStatus(scope.row.isSuspended) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="300"
            class-name="small-padding fixed-width"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="viewDeploy(scope.row)"
                >查看</el-button
              >
              <!-- <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="viewWf(scope.row)"
                >查看流程</el-button
              > -->
              <el-button
                size="mini"
                type="text"
                icon="el-icon-close"
                v-preventReClick
                v-if="!scope.row.isSuspended"
                @click="changeStatus(scope.row, true)"
                >关闭</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-open"
                v-preventReClick
                v-if="scope.row.isSuspended"
                @click="changeStatus(scope.row, false)"
                >开启</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="deployDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-dialog
      title="流程图"
      :visible.sync="openLct"
      width="50%"
      append-to-body
    >
      <el-image :src="lctUrl"></el-image>
    </el-dialog>
    <el-dialog
      title="流程列表"
      :visible.sync="openWf"
      width="50%"
      append-to-body
    >
      <el-table v-loading="wfLoading" :data="processWfList">
        <el-row>
          <el-table-column label="审批意见" align="center" style="float: left">
            <template slot-scope="scope">
              <div
                v-for="item in scope.row.userTaskList"
                :key="item.userId"
                style="width: 100%; float: left; margin: 0 0 5px 0"
              >
                <div
                  style="
                    float: left;
                    width: 30%;
                    text-align: right;
                    padding: 5px 10px 0px 0px;
                  "
                >
                  {{ item.user.userName }}
                </div>
                <div style="float: left; width: 70%; text-align: center">
                  <div
                    v-for="v in item.userCommentList"
                    :key="v.id"
                    style="
                      float: left;
                      width: 100%;
                      background-color: #f8f8f8;
                      padding: 5px 10px 5px 10px;
                    "
                  >
                    <div style="float: left; width: 100%">
                      <div style="float: left">
                        意见：{{ getComment(v.comment) }}
                      </div>
                      <div style="float: right">
                        {{ $parseTime(v.datetime) }}
                      </div>
                    </div>
                    <div style="float: left">
                      评论：{{ getCommentText(v.comment) }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="审批节点"
            align="center"
            prop="nodeName"
            width="120"
          />
        </el-row>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  delProcessDefinition,
  viewProcessDefinitionImage,
  getProcessDefineList,
  changeDefinitionStatus,
  detailWorkFlow
} from '@/api/system/activiti'
import { getMenuPriByMenuId } from '@/api/menu/index'
import config from '@/components/Config'
import { TOKEN_KEY } from '@/utils/constant'
import { ACTIVITI } from '@/utils/activitiConstant'
export default {
  name: 'app',
  data() {
    return {
      apiUrl: config.apiUrl, // 后台部署的api服务
      token: sessionStorage.getItem(TOKEN_KEY),
      workFlowTitle: '',
      deployListTitle: '',
      menuPriPath: [],
      wfLoading: false,
      processWfList: [],
      openWf: false,
      modelRow: {},
      lctUrl: '',
      openLct: false,
      keyDisabled: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      showSearchDeploy: true,
      // 总条数
      total: 0,
      deployTotal: 0,
      // 表格数据
      processDefineList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      openEditor: false,
      openDeployList: false,
      deployLoading: false,
      deployModelList: [],
      editorSrc: '',
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      categoryOptions: ACTIVITI.CATEGORY,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        key: undefined,
        category: 'http://www.activiti.org/processdef',
        enterpriseSecret: undefined
      },
      queryParamsDeploy: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        key: undefined,
        enterpriseSecret: undefined
      },
      taskList: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: '模型名称不能为空', trigger: 'blur' }
        ],
        key: [{ required: true, message: '模型Key不能为空', trigger: 'blur' }],
        version: [
          { required: true, message: '模型版本号不能为空', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '只能输入正整数' }
        ],
        enterpriseSecret: [
          { required: true, message: '企业标识不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    window.getMyVue = this
  },
  created() {
    this.getList()
    this.getRolePriList()
  },
  methods: {
    getComment(comment) {
      return comment.split('&')[0]
    },
    getCommentText(comment) {
      return comment.split('&')[1]
    },
    viewWf(row) {
      debugger
      let data = {
        businessKey: row.businessKey
      }
      this.wfLoading = true
      detailWorkFlow(data).then((res) => {
        if (res.data.success) {
          this.processWfList = res.data.result.sidList
          this.openWf = true
          this.wfLoading = false
        } else {
          this.$msgError(res.data.message)
          this.wfLoading = false
        }
      })
    },
    changeStatus(row, status) {
      this.$confirm(
        '是否确认更改流程定义名称为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        let data = {
          id: row.id,
          isSuspended: status
        }
        changeDefinitionStatus(data).then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('设置成功')
          } else {
            this.$msgError(res.data.message)
            this.loading = false
          }
        })
      })
    },
    getStatus(isSuspended) {
      if (isSuspended) {
        return '挂起'
      } else {
        return '正常'
      }
    },
    /** 查询角色列表 */
    getRolePriList() {
      // debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      getProcessDefineList(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.processDefineList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        name: undefined,
        key: undefined,
        enterpriseSecret: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    viewDeploy(row) {
      var data = {
        deploymentId: row.deploymentId
      }

      viewProcessDefinitionImage(data).then((res) => {
        debugger
        let url = window.URL.createObjectURL(new Blob([res.data]))
        this.lctUrl = url
        this.openLct = true
      })
    },
    deployDelete(row) {
      debugger
      this.$confirm(
        '是否确认删除部署名称为"' + row.name + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        let data = {
          deploymentId: row.deploymentId
        }
        this.loading = true
        delProcessDefinition(data).then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
            this.loading = false
          }
        })
      })
    }
  }
}
</script>
<style>
.iframe {
  width: 100%;
  height: calc(100vh - 85px);
}
/* .el-dialog__close{
    display: none;
  }
  .el-icon-close{
    display: none;
  }
 .el-dialog__body{
  padding:0px 0 0 0;
 } */
</style>
