export const ACTIVITI = {
    CATEGORY:[
        {dictLabel:'行政办公',dictValue:'01'},
        {dictLabel:'采购申请',dictValue:'02'},
        {dictLabel:'IT',dictValue:'03'}
      ],
    PROCESS_INSTANCE_STATUS:[
        {dictLabel:'已作废',dictValue:'01'},
        {dictLabel:'已完成',dictValue:'02'},
        {dictLabel:'审批中',dictValue:'03'}
    ]
}