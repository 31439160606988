<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
        >
          <el-form-item label="菜单名称" prop="menuName">
            <el-input
              v-model="queryParams.menuName"
              placeholder="请输入菜单名称"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="所属企业" prop="enterpriseid">
            <el-input
              v-model="queryParams.enterpriseSecret"
              placeholder="请输入企业标识"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="所属系统" prop="siteId">
            <el-input
              v-model="queryParams.appId"
              placeholder="请输入AppId"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              v-preventReClick
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              v-preventReClick
              @click="handleAdd"
              v-if="menuPriPath.indexOf('add') >= 0"
              >新增</el-button
            >
          </el-col>
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="menuList"
          row-key="guid"
          :tree-props="{ children: 'childrens', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            prop="menuName"
            label="菜单名称"
            :show-overflow-tooltip="true"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="appName"
            label="所属系统"
            :show-overflow-tooltip="true"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="enterpriseSecret"
            label="所属企业"
            :show-overflow-tooltip="true"
            width="160"
          ></el-table-column>
          <el-table-column label="菜单url" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div v-if="scope.row.menuUrl">{{ scope.row.menuUrl }}</div>
              <div v-else>{{ scope.row.routPath }}</div>
            </template>
          </el-table-column>
          <el-table-column label="是否显示" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div v-if="scope.row.menuAvailable == 1">显示</div>
              <div v-else>隐藏</div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="created">
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                v-if="menuPriPath.indexOf('edit') >= 0"
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-plus"
                v-preventReClick
                @click="handleAdd(scope.row)"
                v-if="menuPriPath.indexOf('add') >= 0"
                >新增</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-if="
                  menuPriPath.indexOf('delete') >= 0 &&
                  (scope.row.childrens == null ||
                    scope.row.childrens == undefined ||
                    scope.row.childrens.length == 0)
                "
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 添加或修改菜单对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body>
      <div>
        <el-steps
          :active="dialog.step"
          align-center
          process-status="process"
          finish-status="success"
        >
          <el-step title="菜单信息"></el-step>
          <el-step title="操作权限"></el-step>
        </el-steps>
        <div class="dialog-form">
          <el-form
            ref="formStep1"
            :model="form"
            :rules="rules"
            label-width="80px"
            v-show="dialog.step === 0"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="所属企业" prop="enterpriseSecret">
                  <el-input
                    v-model="form.enterpriseSecret"
                    placeholder="请输入企业标识"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="所属系统" prop="appId">
                  <el-input v-model="form.appId" placeholder="请输入系统appId">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="上级菜单" prop="parentId">
                  <treeselect
                    v-model="form.parentId"
                    :options="menuOptions"
                    :normalizer="normalizer"
                    @open="openMenu"
                    :show-count="true"
                    placeholder="选择上级菜单"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="菜单图标">
                  <el-input
                    v-model="form.menuIcon"
                    placeholder="请输入图标路径或者图标标识"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="菜单名称" prop="menuName">
                  <el-input
                    v-model="form.menuName"
                    placeholder="请输入菜单名称"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="显示排序" prop="menuOrder">
                  <el-input-number
                    v-model="form.menuOrder"
                    controls-position="right"
                    :min="0"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="菜单地址" prop="menuUrl">
                  <el-input
                    v-model="form.menuUrl"
                    placeholder="请输入菜单地址"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  v-if="form.menuType == 'M'"
                  label="页面路径"
                  prop="componentPath"
                >
                  <el-input
                    v-model="form.componentPath"
                    placeholder="请输入页面组件路径"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  v-if="form.menuType == 'M'"
                  label="路由地址"
                  prop="routPath"
                >
                  <el-input
                    v-model="form.routPath"
                    placeholder="请输入路由地址"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="白名单url"
                  prop="whiteUrl"
                  v-if="form.menuType == 'M'"
                >
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="多个逗号隔开"
                    v-model="form.whiteUrl"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  v-if="form.menuType == 'M'"
                  label="是否显示"
                  prop="menuAvailable"
                >
                  <el-radio-group v-model="form.menuAvailable">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            ref="formStep2"
            :rules="rules"
            label-width="80px"
            v-show="dialog.step === 1"
          >
            <div v-for="(item, index) in menuPriList" :key="item.guid">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="权限名称">
                    <el-input
                      v-model="item.privilegeName"
                      placeholder="请输入权限名称"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="权限行为">
                    <el-input
                      v-model="item.privilegeAction"
                      controls-position="right"
                      placeholder="请输入权限行为"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <template>
                      <el-button @click="delMenuPri(index)">删除</el-button>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-col :span="24">
                <template>
                  <el-button @click="addMenuPri()">添加</el-button>
                </template>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-preventReClick @click="previous" v-if="dialog.step > 0"
          >上一步</el-button
        >
        <el-button
          type="primary"
          v-preventReClick
          @click="next"
          v-if="dialog.step < 1"
          >下一步</el-button
        >
        <el-button
          type="primary"
          v-preventReClick
          @click="submitForm"
          v-if="dialog.step === 1"
          >提交</el-button
        >
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMenu,
  delMenu,
  addMenu,
  updateMenu,
  menuTreeList
} from '@/api/system/menu'
import { getMenuPriByMenuId } from '@/api/menu/index'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//  import IconSelect from '@/components/IconSelect/index.vue';

export default {
  name: 'Menu',
  components: { Treeselect },
  data() {
    return {
      menuPriPath: [],
      updateFlag: false,
      //企业数据
      enterpriseOptions: [],
      curQuerySelectEnterpriseId: null,
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      //系统站点
      siteList: [],
      // 菜单表格树数据
      menuList: [],
      // 菜单操作数据
      menuPriList: [],
      // 菜单树选项
      menuOptions: [],
      // 新增页面菜单树选项
      addMenuOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 显示状态数据字典
      visibleOptions: [],
      // 菜单状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        menuName: undefined,
        visible: undefined,
        appId: undefined,
        enterpriseSecret: undefined
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      },
      // 新增表单
      dialog: {
        // 弹出层标题
        title: '',
        // 是否显示弹出层
        open: false,
        step: 0
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        menuName: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '路由地址不能为空', trigger: 'blur' }
        ],
        enterpriseSecret: [
          { required: true, message: '所属企业标识', trigger: 'change' }
        ],
        appId: [{ required: true, message: '所属系统AppId', trigger: 'change' }]
      }
    }
  },
  created() {
    this.handleQuery()
    this.getRolePriList()
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      debugger
      var data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (var i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    delMenuPri(index) {
      this.form.menuPriList[index]['ishow'] = -1
      this.menuPriList.splice(index, 1)
    },
    addMenuPri() {
      this.menuPriList.push({
        guid: '',
        privilegeName: '',
        privilegeAction: ''
      })
    },
    openMenu(instanceId) {
      var appId = this.form.appId
      var enterpriseSecret = this.form.enterpriseSecret
      var data = {
        appId: appId,
        enterpriseSecret: enterpriseSecret
      }
      if (
        appId != '' &&
        appId != undefined &&
        enterpriseSecret != '' &&
        enterpriseSecret != 'undefined'
      ) {
        menuTreeList(data).then((res) => {
          if (res.data.success) {
            this.menuOptions = res.data.result
          } else {
            this.$msgError(res.data.message)
          }
        })
      }
    },
    selectChanged(value) {
      //清空上级菜单
      this.form.parentId = ''
      this.menuOptions = []
    },
    // 选择图标
    selected(name) {
      this.form.icon = name
    },
    /** 查询菜单列表 */
    getList() {
      this.loading = true
      menuTreeList(this.queryParams).then((res) => {
        //数据转换
        if (res.data.success) {
          this.menuList = res.data.result
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    /** 下一步 */
    next() {
      const form = this.dialog.step === 0 ? 'formStep1' : 'formStep2'
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.dialog.step++
        }
      })
    },
    /** 上一步 */
    previous() {
      this.dialog.step--
    },
    //   changeTree(tdata){
    // 　　if(tdata!=null&& tdata.length>0){
    //         for(var i=0;i<tdata.length;i++){
    //           var v=tdata[i];
    //           if((v.childrens==null||(v.childrens).length==0)){
    //             if(v.menuPriList!=null&&(v.menuPriList).length>0){
    //                 var chids=[];
    //                 for(var j=0;j<(v.menuPriList).length;j++){
    //                   var dto=v.menuPriList[j];
    //                   var para={
    //                      guid:dto.guid,
    //                      menuName:dto.privilegeName,
    //                      menuUrl:dto.privilegeAction,
    //                      parentId:dto.menuGuid,
    //                      createTime:dto.createTime,
    //                      menuType:"F",
    //                      siteId:dto.siteId,
    //                      enterpriseid:dto.enterpriseid
    //                   }
    //                   chids.push(para);
    //               }
    //               v.childrens=chids;
    //             }

    //           }else{
    //             this.changeTree(v.childrens);
    //           }
    // 　　　　}
    // 　　}
    //     return tdata;
    //   },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.childrens && !node.childrens.length) {
        delete node.childrens
      }
      return {
        id: node.guid,
        label: node.menuName,
        children: node.childrens
      }
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {},
    // 显示状态字典翻译
    visibleFormat(row, column) {
      if (row.menuType == 'F') {
        return ''
      }
      return this.$selectDictLabel(this.visibleOptions, row.visible)
    },
    // 菜单状态字典翻译
    statusFormat(row, column) {
      if (row.menuType == 'F') {
        return ''
      }
      return this.$selectDictLabel(this.statusOptions, row.status)
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        guid: undefined,
        parentId: undefined,
        menuName: undefined,
        menuIcon: undefined,
        menuUrl: undefined,
        menuType: 'M',
        menuOrder: undefined,
        isFrame: '1',
        visible: '0',
        status: '0',
        siteId: undefined,
        enterpriseid: undefined
      }
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.updateFlag = false
      this.reset()
      this.dialog.step = 0
      if (row != null && row.guid) {
        this.form.parentId = row.guid
        this.form.appId = row.appId
        this.form.enterpriseSecret = row.enterpriseSecret
        this.form.menuPriList = []
        this.menuPriList = []
        if (this.menuOptions == null || this.menuOptions.length == 0) {
          var params = {
            appId: row.appId,
            enterpriseSecret: row.enterpriseSecret
          }
          menuTreeList(params).then((res) => {
            if (res.data.success) {
              this.menuOptions = res.data.result
            } else {
              this.$msgError(res.data.message)
            }
          })
        }
      } else {
        this.form.parentId = undefined
      }
      this.form.menuAvailable = '1'

      this.open = true
      this.title = '添加菜单'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.updateFlag = true
      this.reset()
      this.dialog.step = 0
      var params = {
        appId: row.appId,
        enterpriseSecret: row.enterpriseSecret
      }
      menuTreeList(params).then((res) => {
        if (res.data.success) {
          this.menuOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
      var params = {
        guid: row.guid
      }

      //菜单
      getMenu(params).then((res) => {
        if (res.data.success) {
          this.form = res.data.result
          this.menuPriList = JSON.parse(JSON.stringify(this.form.menuPriList))
          if (
            this.form.menuAvailable == null ||
            this.form.menuAvailable == ''
          ) {
            this.form.menuAvailable = '1'
          }
          this.form.menuType = 'M'
          this.open = true
          this.title = '修改菜单'
        } else {
          this.$msgError(res.data.message)
        }
      })
      // }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['formStep1'].validate((valid) => {
        if (valid) {
          this.menuPriList.forEach((item) => {
            if (item.guid == '') {
              this.form.menuPriList.push(item)
            } else {
              const i = this.form.menuPriList.findIndex((a) => {
                return a.guid == item.guid
              })
              this.form.menuPriList[i] = item
            }
          })
          if (this.form.guid != undefined) {
            updateMenu(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addMenu(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.menuName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delMenu(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
