<template>
  <div class="app-container">
    <el-row>
      <el-col>
        <el-button
          v-loading="buildLoading"
          type="primary"
          v-preventReClick
          @click="handleGeneratePairKey"
          >生成PublicKey & PrivateKey</el-button
        >
        <el-button
          v-loading="downloading"
          type="primary"
          v-preventReClick
          @click="handleDownloadPairKey"
          >下载PublicKey & PrivateKey</el-button
        >
        <el-divider></el-divider>
        <el-form
          ref="formAes1"
          :model="formAes1"
          :rules="rulesEncry"
          v-loading="encryptLoading"
        >
          <el-form-item label="字符串" prop="encryptStr">
            <el-input
              v-model="formAes1.encryptStr"
              placeholder="请输入要加密的字符串"
            />
          </el-form-item>
          <el-form-item label="加密结果">
            <el-input v-model="formAes1.encryptResult" type="textarea" />
          </el-form-item>
          <el-button type="primary" v-preventReClick @click="handleEncrypt"
            >加密</el-button
          >
        </el-form>
        <el-divider></el-divider>
        <el-form
          ref="formAes2"
          :model="formAes2"
          :rules="rulesDecry"
          v-loading="decryptLoading"
        >
          <el-form-item label="加密字符串" prop="decryptStr">
            <el-input
              v-model="formAes2.decryptStr"
              type="textarea"
              placeholder="请输入加密的字符串"
            />
          </el-form-item>
          <el-form-item label="解密结果">
            <el-input v-model="formAes2.decryptResult" />
          </el-form-item>
          <el-button type="primary" v-preventReClick @click="handleDecrypt"
            >解密</el-button
          >
        </el-form>
      </el-col>
      <!-- <ImageUpload ></ImageUpload> -->
    </el-row>
  </div>
</template>
<script>
import {
  generatePairKey,
  encrypt,
  decrypt,
  downloadPairKey,
  uploadFile
} from '@/api/system/developer'
import { TOKEN_KEY } from '@/utils/constant'
import ImageUpload from '@/components/ImageUpload/index'
export default {
  name: 'aes',
  data() {
    return {
      headers: {
        'X-Access-Token': sessionStorage.getItem(TOKEN_KEY),
        appId: 'b24a1abf465545398a3f5d116b3ebd2d'
      },
      buildLoading: false,
      downloading: false,
      encryptLoading: false,
      decryptLoading: false,
      formAes1: {
        encryptStr: undefined,
        encryptResult: undefined
      },
      formAes2: {
        decryptStr: undefined,
        decryptResult: undefined
      },
      rulesEncry: {
        encryptStr: [{ required: true, message: '要加密的字符串不能为空' }]
      },
      rulesDecry: {
        decryptStr: [{ required: true, message: '要加密的字符串不能为空' }]
      }
    }
  },
  methods: {
    handleGeneratePairKey() {
      this.buildLoading = true
      generatePairKey().then((res) => {
        if (res.data.success) {
          this.$msgSuccess('生成成功')
          this.buildLoading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    handleDownloadPairKey() {
      this.downloading = true
      let data = {}
      downloadPairKey(data).then((res) => {
        if (res.data.success) {
          this.zipDownload('KeyPaires.zip', res.data)
          this.$msgSuccess('下载成功')
          this.downloading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    zipDownload(name, data) {
      let link = document.createElement('a')
      let blob = new Blob([data])
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    handleEncrypt() {
      this.$refs['formAes1'].validate((valid) => {
        if (valid) {
          let data = {
            encryptString: this.formAes1.encryptStr
          }
          encrypt(data).then((res) => {
            if (res.data.success) {
              this.formAes1.encryptResult = res.data.result
            } else {
              this.$msgError(res.data.message)
            }
          })
        }
      })
    },
    handleDecrypt() {
      this.$refs['formAes2'].validate((valid) => {
        if (valid) {
          let data = {
            decryptString: this.formAes2.decryptStr
          }
          decrypt(data).then((res) => {
            if (res.data.success) {
              this.formAes2.decryptResult = res.data.result
            } else {
              this.$msgError(res.data.message)
            }
          })
        }
      })
    }
  },
  components: {
    ImageUpload //注册上传组建
  }
}
</script>
<style>
</style>