<template>
  <div>
    <div class="viewInfoContent">
      <ul>
        <li>
          <span class="title">客户名称：</span
          ><span class="content">{{ purchase.customerInfo.customerName }}</span>
        </li>
        <li>
          <span class="title">公司名称：</span
          ><span class="content">{{ purchase.customerInfo.companyName }}</span>
        </li>
      </ul>
      <el-divider><span style="font-size: 12px">采购信息</span></el-divider>
      <ul>
        <li>
          <span class="title">产品名称：</span
          ><span class="content">{{ purchase.purchaseProductName }}</span>
        </li>
        <li>
          <span class="title">产品分类：</span
          ><span class="content">{{
            purchase.purchaseProductCategoryName
          }}</span>
        </li>
        <li>
          <span class="title">采购日期：</span
          ><span class="content">{{
            $parseTime(purchase.purchaseDate, '{y}-{m}-{d}')
          }}</span>
        </li>
        <li>
          <span class="title">产品单价：</span
          ><span class="content">{{ purchase.purchaseProductPrice }}元</span>
        </li>
        <li>
          <span class="title">产品数量：</span
          ><span>{{ purchase.purchaseProductNum }}</span>
        </li>
        <li>
          <span class="title">产品总价：</span
          ><span class="content">{{ purchase.purchaseProductTotal }}元</span>
        </li>
        <li>
          <span class="title">产品配置：</span
          ><span
            class="content"
            v-html="$parseText(purchase.purchaseProductDesc)"
          ></span>
        </li>
        <li>
          <span class="title">收货信息：</span
          ><span
            class="content"
            v-html="$parseText(purchase.deliverInfo)"
          ></span>
        </li>
        <li>
          <span class="title">发票信息：</span
          ><span
            class="content"
            v-html="$parseText(purchase.invoiceInfo)"
          ></span>
        </li>
        <li>
          <span class="title">备注：</span
          ><span class="content" v-html="$parseText(purchase.comment)"></span>
        </li>
      </ul>
    </div>
    <!-- <el-divider></el-divider>
  <div style="text-align: center;"> <el-button @click="edit" type="primary">修改联系人</el-button></div> -->
  </div>
</template>
<script>
export default {
  name: 'ViewPurchase',
  data() {
    return {
      purchase: this.value,
      myValue: this.value
    }
  },
  components: {},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mounted() {},
  methods: {
    edit() {
      this.$emit('editPurchase', this.purchase)
    }
  },
  watch: {
    value(newValue) {
      this.purchase = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.viewInfoContent {
  // display: grid;
  ul,
  li {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  li {
    line-height: 28px;
    margin-bottom: 1px;
    .title {
      width: 20%;
      text-align: right;
      vertical-align: top;
    }

    .content {
      width: 80%;
      background-color: #f5f5f5;
      padding: 0 5px 0 5px;
    }
  }
  span {
    font-size: 14px;
    color: #72767b;
    display: inline-block;
  }
}
</style>
