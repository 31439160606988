const state = {
  job:  window.sessionStorage.getItem('userinfo') === null || window.sessionStorage.getItem('userinfo') === '' ? '' : JSON.parse(window.sessionStorage.getItem('userinfo')).photo || '',
  uname: window.sessionStorage.getItem('userinfo') === null || window.sessionStorage.getItem('userinfo') === '' ? '' : JSON.parse(window.sessionStorage.getItem('userinfo')).name || '',
  uphone: window.sessionStorage.getItem('userinfo') === null || window.sessionStorage.getItem('userinfo') === '' ? '' : JSON.parse(window.sessionStorage.getItem('userinfo')).phone || '',
  orgFullName: window.sessionStorage.getItem('userinfo') === null || window.sessionStorage.getItem('userinfo') === '' ? '' : JSON.parse(window.sessionStorage.getItem('userinfo')).orgFullName || '',
  uguid: window.sessionStorage.getItem('userinfo') === null || window.sessionStorage.getItem('userinfo') === '' ? '' : JSON.parse(window.sessionStorage.getItem('userinfo')).guid || ''
}
const actions = {}
const mutations = {
  SET_USER (state, obj) {
    // console.log(obj)
    state.uphoto = obj.photo
    state.uname = obj.name
    state.uphone = obj.phone
    state.uguid = obj.guid
    state.orgFullName=obj.orgFullName
    window.sessionStorage.setItem('userinfo', JSON.stringify(obj))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
