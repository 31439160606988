<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="90px"
        >
          <el-form-item label="商机名称" prop="opportunityName">
            <el-input
              v-model="queryParams.opportunityName"
              placeholder="请输入关键词"
              clearable
            />
          </el-form-item>
          <el-form-item label="商机来源" prop="opportunitySource">
            <el-select
              v-model="queryParams.opportunitySource"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in opportunitySourceOptions"
                :key="dict.itemKey"
                :label="dict.itemName"
                :value="dict.itemKey"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商机类型" prop="opportunityCategory">
            <el-select
              v-model="queryParams.opportunityCategory"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in opportunityCategoryOptions"
                :key="dict.itemKey"
                :label="dict.itemName"
                :value="dict.itemKey"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商机阶段" prop="opportunityStep">
            <el-select
              v-model="queryParams.opportunityStep"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in opportunityStepOptions"
                :key="dict.itemKey"
                :label="dict.itemName"
                :value="dict.itemKey"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商机状态" prop="opportunityStatus">
            <el-select
              v-model="queryParams.opportunityStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="dict in opportunityStatusOptions"
                :key="dict.itemKey"
                :label="dict.itemName"
                :value="dict.itemKey"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <!-- <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                v-preventReClick
                @click="handleAdd"
                >新增</el-button
              > -->
          </el-col>
          <right-toolbar
            :show-search.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          border
          v-loading="loading"
          :data="dataList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed
            min-width="200px"
            label="商机标题"
            key="opportunityName"
            prop="opportunityName"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link @click="handleView(scope.row)" type="primary">{{
                scope.row.opportunityName
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            min-width="200px"
            label="客户名称"
            key="customerInfo.customerName"
            prop="customerInfo.customerName"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link @click="handleCustomerView(scope.row)" type="primary">{{
                scope.row.customerInfo.customerName
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            min-width="200px"
            label="公司名称"
            key="customerInfo.companyName"
            prop="customerInfo.companyName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100px"
            label="关联联系人"
            key="opportunityContactName"
            prop="opportunityContactName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="120px"
            label="预计销售金额"
            key="opportunityPrice"
            prop="opportunityPrice"
            align="right"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="120px"
            label="预计签单日期"
            key="opportunityDate"
            prop="opportunityDate"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{
                $parseTime(scope.row.opportunityDate, '{y}-{m}-{d}')
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            min-width="100px"
            label="销售阶段"
            key="opportunityStepName"
            prop="opportunityStepName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100px"
            label="创建人"
            align="center"
            key="creatorName"
            prop="creatorName"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="创建时间" prop="createTime" min-width="160">
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            align="center"
            min-width="180"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleView(scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" v-if="open">
      <editOpportunity
        v-model="opportunityEntity.guid"
        :customer_guid="opportunityEntity.customerGuid"
        @editResult="editOpportunityResult"
      ></editOpportunity>
    </el-dialog>
    <el-drawer
      :title="drawerCustomer.title"
      :visible.sync="drawerCustomer.visible"
      direction="rtl"
      :with-header="false"
      :destroy-on-close="true"
      size="70%"
    >
      <viewCustomer v-model="opportunityEntity.customerInfo"></viewCustomer>
    </el-drawer>
    <el-drawer
      :title="opportunityEntity.opportunityName"
      :visible.sync="drawer.visible"
      v-if="drawer.visible"
      direction="rtl"
      :with-header="false"
      size="70%"
    >
      <div class="drawerTitle">
        <div class="kh">
          <span
            ><i class="el-icon-user-solid"></i>
            {{ opportunityEntity.opportunityName }}</span
          >
        </div>
        <div class="sub">
          <span>创建人：{{ opportunityEntity.creatorName }}</span>
          <span>创建时间：{{ $parseTime(opportunityEntity.created) }}</span>
        </div>
      </div>
      <el-divider><i class="el-icon-arrow-down"></i></el-divider>
      <div class="drawerContent">
        <div
          :style="{ height: windowHeight + 'px', 'overflow-y': 'auto' }"
          class="left"
        >
          <el-tabs type="border-card">
            <el-tab-pane>
              <span slot="label"><i class="el-icon-date"></i> 商机信息</span>
              <viewOpportunity
                v-model="opportunityEntity"
                @editContact="handleUpdate"
              ></viewOpportunity>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><i class="el-icon-date"></i> 联系人</span>
              <customerContact
                v-model="opportunityEntity.customerInfo.guid"
              ></customerContact>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div
          class="right"
          :style="{ height: windowHeight + 'px', overflow: 'hidden' }"
        >
          <customerFollow
            v-model="opportunityEntity.customerInfo.guid"
          ></customerFollow>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  listOpportunity,
  addOpportunity,
  updateOpportunity,
  delOpportunity
} from '@/api/crm/opportunity'
import { listDict } from '@/api/maindata/datatype'
import editOpportunity from '@/views/system/crm/common/editOpportunity.vue'
import viewOpportunity from '@/views/system/crm/common/viewOpportunity.vue'
import customerFollow from '@/views/system/crm/common/customerFollow.vue'
import customerContact from '@/views/system/crm/common/customerContact.vue'
import viewCustomer from '@/views/system/crm/common/viewCustomer.vue'
import { getMenuPriByMenuId } from '@/api/menu/index'
export default {
  name: 'Contract',
  components: {
    editOpportunity,
    viewOpportunity,
    customerFollow,
    customerContact,
    viewCustomer
  },
  data() {
    return {
      windowHeight: 0,
      opportunityEntity: {},
      drawer: {
        title: '',
        visible: false,
        customer: {}
      },
      drawerCustomer: {
        title: '',
        visible: false,
        customer: {}
      },
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      opportunityStatusOptions: [],
      //分类列表
      opportunityCategoryOptions: [],
      // 来源数据字典
      opportunitySourceOptions: [],
      // 销售阶段数据字典
      opportunityStepOptions: [],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        contactName: undefined,
        opportunityStatus: undefined,
        opportunityCategory: undefined,
        opportunitySource: undefined,
        opportunityStep: undefined
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      }
    }
  },
  created() {
    this.getRolePriList()
    this.initWindowHeight()
  },
  mounted() {
    this.getOpportunityCategoryList()
    this.getOpportunitySourceList()
    this.getOpportunityStatusList()
    this.getOpportunityStepList()
    this.getList()
  },
  methods: {
    initWindowHeight() {
      let pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      this.windowHeight = pageHeight - 140
      console.log(this.windowHeight)
    },
    /** 查询角色列表 */
    getRolePriList() {
      let data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取商机来源数据字典
    getOpportunitySourceList() {
      listDict({
        typeKey: 'crmOpportunitySource'
      }).then((res) => {
        if (res.data.success) {
          this.opportunitySourceOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取商机来源数据字典
    getOpportunityCategoryList() {
      listDict({
        typeKey: 'crmOpportunityCategory'
      }).then((res) => {
        if (res.data.success) {
          this.opportunityCategoryOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取商机销售阶段数据字典
    getOpportunityStepList() {
      listDict({
        typeKey: 'crmOpportunityStep'
      }).then((res) => {
        if (res.data.success) {
          this.opportunityStepOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    //获取商机销售阶段数据字典
    getOpportunityStatusList() {
      listDict({
        typeKey: 'crmOpportunityStatus'
      }).then((res) => {
        if (res.data.success) {
          this.opportunityStatusOptions = res.data.result
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      listOpportunity(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {}
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加商机'
      this.$resetForm('form')
    },
    handleView(row) {
      this.opportunityEntity = row
      this.drawer.visible = true
    },
    handleCustomerView(row) {
      this.opportunityEntity = row
      this.drawerCustomer.visible = true
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.open = true
      this.title = '修改商机'
      this.opportunityEntity = row
    },
    editOpportunityResult() {
      this.open = false
      this.getList()
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form)
          if (this.form.guid !== undefined) {
            updateOpportunity(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addOpportunity(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.opportunityName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delOpportunity(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style scoped lang="scss">
.dialog-footer {
  text-align: center;
}

.el-drawer__header {
  margin-bottom: 10px;
}

.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}

.drawerContent {
  display: flex;

  .left {
    width: 69%;
    margin: 0 0 0 10px;
  }

  .right {
    width: 28%;
    margin: 0 0 0 10px;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    padding: 8px;
    .header {
      padding: 0 0 0 10px;

      span {
        line-height: 40px;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
