<template>
    <div v-if="dataType == 'singleSelect'">
        <el-select v-model="myValue" placeholder="请选择">
            <el-option v-for="item in dataOptions" :key="item.guid" :label="item.itemName"
                :value="item.itemKey"></el-option>
        </el-select>
    </div>
    <div v-else-if="dataType == 'multSelect'">
        <el-select v-model="myValue" multiple placeholder="请选择">
            <el-option v-for="item in dataOptions" :key="item.guid" :label="item.itemName"
                :value="item.itemKey"></el-option>
        </el-select>
    </div>
    <div v-else-if="dataType=='checkbox'">
        <el-checkbox-group v-model="myValue">

    <el-checkbox v-for="item in dataOptions" :key="item.guid" 
                :label="item.itemKey">{{ item.itemName }}</el-checkbox>
  </el-checkbox-group>
    </div>
</template>
<script>
import { listDict } from '@/api/maindata/datatype'
export default {
    data() {
        return {
            dataOptions: [],
            myValue: undefined,
        }
    },
    name: 'maindata',
    props: {
        value: {
            type: String,
            default: ''
        },
        dataType: String,//空间类型
        dataKey: String,//数据类型Key
    },
    mounted() {
        this.initValue();
    },
    created() {
        this.getListDict();
    },
    methods: {
        initValue() {
            if (this.dataType == 'singleSelect') {
                this.myValue = this.value;
            } else if (this.dataType == 'multSelect' || this.dataType=='checkbox') {
                if (!this.value) {
                    this.myValue = [];
                } else {
                    this.myValue = this.value.split(',');
                }
            }
        },
        getListDict() {
            console.log(this.value)
            if (!this.dataKey) return;
            listDict({
                typeKey: this.dataKey
            }).then(res => {
                if (res.data.success) {
                    this.dataOptions = res.data.result;
                } else {
                    console.log(res.data.message)
                }
            })
        }
    },
    watch: {
        value(newValue) {
            // this.myValue = newValue;
            if (this.dataType == 'singleSelect') {
                this.myValue = newValue;
            } else if (this.dataType == 'multSelect' || this.dataType=='checkbox') {
                if (!newValue) {
                    this.myValue = [];
                } else {
                    this.myValue = newValue.split(',');
                }

            }
        },
        myValue(newValue) {
            let a = '';
            if (this.dataType == 'multSelect' || this.dataType=='checkbox') {
                a = newValue.join(',');
            } else {
                a = newValue;
            }
            this.$emit('input', a);
        }
    }
}
</script>
<style></style>