import {request,post} from '@/plugins/request';

  // 查询详细
  export function getKupin(data) {
    return post('/kupin/v0.1/kupin/kupin/ct/detail',data);
  }
  
  export function listKupin(data) {
    return post('/kupin/v0.1/kupin/kupin/ct/list',data);
  }
  // 新增
  export function addKupin(data) {
    return post('/kupin/v0.1/kupin/kupin/ct/create',data);
  }
  // 修改
  export function updateKupin(data) {
    return post('/kupin/v0.1/kupin/kupin/ct/update',data);
  }
  
  // 删除
  export function delKupin(data) {
    return post('/kupin/v0.1/kupin/kupin/ct/delete',data);
  }
  