import {request,post} from '@/plugins/request';

  // 查询详细
  export function getCustomer(data) {
    return post('/crm/v0.1/crm/crm/customer/detail',data);
  }
  
  export function listCustomer(data) {
    return post('/crm/v0.1/crm/crm/customer/list',data);
  }
  // 新增
  export function addCustomer(data) {
    return post('/crm/v0.1/crm/crm/customer/create',data);
  }
  // 修改
  export function updateCustomer(data) {
    return post('/crm/v0.1/crm/crm/customer/update',data);
  }
  
  // 删除
  export function delCustomer(data) {
    return post('/crm/v0.1/crm/crm/customer/delete',data);
  }