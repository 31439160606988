import {request,post} from '@/plugins/request';

  // 查询详细
  export function getFollow(data) {
    return post('/crm/v0.1/crm/crm/follow/detail',data);
  }
  
  export function listFollow(data) {
    return post('/crm/v0.1/crm/crm/follow/list',data);
  }
  // 新增
  export function addFollow(data) {
    return post('/crm/v0.1/crm/crm/follow/create',data);
  }
  // 修改
  export function updateFollow(data) {
    return post('/crm/v0.1/crm/crm/follow/update',data);
  }
  
  // 删除
  export function delFollow(data) {
    return post('/crm/v0.1/crm/crm/follow/delete',data);
  }