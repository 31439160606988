<template>
  <div>
    <div class="viewInfoContent">
      <ul>
        <li>
          <span class="title">客户名称：</span
          ><span>{{ opportunity.customerInfo.customerName }}</span>
        </li>
        <li>
          <span class="title">公司名称：</span
          ><span>{{ opportunity.customerInfo.companyName }}</span>
        </li>
      </ul>
      <el-divider><span style="font-size: 12px">商机信息</span></el-divider>
      <ul>
        <li>
          <span class="title">商机名称：</span
          ><span class="content">{{ opportunity.opportunityName }}</span>
        </li>
        <li>
          <span class="title">关联联系人：</span
          ><span class="content">{{ opportunity.opportunityContactName }}</span>
        </li>
        <li>
          <span class="title">商机来源：</span
          ><span class="content">{{ opportunity.opportunitySourceName }}</span>
        </li>
        <li>
          <span class="title">商机类型：</span
          ><span class="content">{{
            opportunity.opportunityCategoryName
          }}</span>
        </li>
        <li>
          <span class="title">商机状态：</span
          ><span class="content">{{ opportunity.opportunityStatusName }}</span>
        </li>
        <li>
          <span class="title">商机阶段：</span
          ><span class="content">{{ opportunity.opportunityStepName }}</span>
        </li>
        <li>
          <span class="title">获取商机日期：</span
          ><span class="content">{{
            $parseTime(opportunity.opportunityGetDate, '{y}-{m}-{d}')
          }}</span>
        </li>
        <li>
          <span class="title">预计销售金额：</span
          ><span class="content">{{ opportunity.opportunityPrice }}</span>
        </li>
        <li>
          <span class="title">预计签单日期：</span
          ><span class="content">{{
            $parseTime(opportunity.opportunityDate, '{y}-{m}-{d}')
          }}</span>
        </li>
        <li>
          <span class="title">备注：</span
          ><span
            class="content"
            v-html="$parseText(opportunity.comment)"
          ></span>
        </li>
      </ul>
    </div>
    <!-- <el-divider></el-divider>
  <div style="text-align: center;"> <el-button @click="editContact" type="primary">修改联系人</el-button></div> -->
  </div>
</template>
<script>
export default {
  name: 'ViewOpportunity',
  data() {
    return {
      opportunity: this.value,
      myValue: this.value
    }
  },
  components: {},
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mounted() {},
  methods: {
    edit() {
      this.$emit('edit', this.opportunity)
    }
  },
  watch: {
    value(newValue) {
      this.opportunity = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.viewInfoContent {
  // display: grid;
  ul,
  li {
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  li {
    line-height: 28px;
    margin-bottom: 1px;
    .title {
      width: 20%;
      text-align: right;
      vertical-align: top;
    }
    .content {
      width: 80%;
      background-color: #f5f5f5;
      padding: 0 5px 0 5px;
    }
  }
  span {
    font-size: 14px;
    color: #72767b;
    display: inline-block;
  }
}
</style>
