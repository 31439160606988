<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--用户数据-->
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="90px"
        >
          <el-form-item label="产品名称" prop="purchaseProductName">
            <el-input
              v-model="queryParams.purchaseProductName"
              placeholder="请输入关键词"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <!-- <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                v-preventReClick
                @click="handleAdd"
                >新增</el-button
              > -->
          </el-col>
          <right-toolbar
            :show-search.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          border
          v-loading="loading"
          :data="dataList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="产品名称"
            key="purchaseProductName"
            prop="purchaseProductName"
            fixed
            min-width="200px"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link @click="handleView(scope.row)" type="primary">{{
                scope.row.purchaseProductName
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="客户名称"
            fixed
            key="customerInfo.customerName"
            prop="customerInfo.customerName"
            min-width="200px"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-link @click="handleCustomerView(scope.row)" type="primary">{{
                scope.row.customerInfo.customerName
              }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            label="公司名称"
            key="customerInfo.companyName"
            prop="customerInfo.companyName"
            min-width="200px"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100px"
            align="right"
            label="产品单价(元)"
            key="purchaseProductPrice"
            prop="purchaseProductPrice"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100px"
            align="center"
            label="产品数量"
            key="purchaseProductNum"
            prop="purchaseProductNum"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            min-width="100px"
            align="right"
            label="产品总价(元)"
            key="purchaseProductTotal"
            prop="purchaseProductTotal"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="采购日期" prop="createTime" min-width="160">
            <template slot-scope="scope">
              <span>{{
                $parseTime(scope.row.purchaseDate, '{y}-{m}-{d}')
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="创建人"
            align="center"
            key="creatorName"
            prop="creatorName"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="创建时间" prop="createTime" min-width="160">
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            min-width="180"
            fixed="right"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleView(scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-preventReClick
                @click="handleUpdate(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-preventReClick
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px" v-if="open">
      <editPurchase
        v-model="purchaseEntity.guid"
        :customer_guid="purchaseEntity.customerGuid"
        @editResult="editPurchaseResult"
      ></editPurchase>
    </el-dialog>
    <el-drawer
      :title="drawerCustomer.title"
      :visible.sync="drawerCustomer.visible"
      direction="rtl"
      :with-header="false"
      :destroy-on-close="true"
      size="70%"
    >
      <viewCustomer v-model="purchaseEntity.customerInfo"></viewCustomer>
    </el-drawer>
    <el-drawer
      :title="purchaseEntity.purchaseProductName"
      :visible.sync="drawer.visible"
      v-if="drawer.visible"
      direction="rtl"
      :with-header="false"
      size="50%"
    >
      <div class="drawerTitle">
        <div class="kh">
          <span
            ><i class="el-icon-user-solid"></i>
            {{ purchaseEntity.purchaseProductName }}</span
          >
        </div>
        <div class="sub">
          <span>创建人：{{ purchaseEntity.creatorName }}</span>
          <span>创建时间：{{ $parseTime(purchaseEntity.created) }}</span>
        </div>
      </div>
      <el-divider><i class="el-icon-arrow-down"></i></el-divider>
      <div class="drawerContent">
        <div class="left">
          <el-tabs type="border-card">
            <el-tab-pane>
              <span slot="label"><i class="el-icon-date"></i> 产品信息</span>
              <viewPurchase
                v-model="purchaseEntity"
                @editContact="handleUpdate"
              ></viewPurchase>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  listPurchase,
  getPurchase,
  addPurchase,
  updatePurchase,
  delPurchase
} from '@/api/crm/purchase'
import editPurchase from '@/views/system/crm/common/editPurchase.vue'
import viewPurchase from '@/views/system/crm/common/viewPurchase.vue'
import { getMenuPriByMenuId } from '@/api/menu/index'
import viewCustomer from '@/views/system/crm/common/viewCustomer.vue'
export default {
  name: 'Purchase',
  components: {
    editPurchase,
    viewPurchase,
    viewCustomer
  },
  data() {
    return {
      purchaseEntity: {},
      drawer: {
        title: '',
        visible: false,
        customer: {}
      },
      drawerCustomer: {
        title: '',
        visible: false,
        customer: {}
      },
      menuPriPath: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      //分类列表
      categoryOptions: [],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        purchaseProductName: undefined
      },
      defaultProps: {
        children: 'childrens',
        label: 'name'
      }
    }
  },
  created() {
    this.getRolePriList()
    //   this.getCategoryList();
    this.getList()
  },
  methods: {
    /** 查询角色列表 */
    getRolePriList() {
      let data = {
        menuGuid: this.$route.query.menuGuid
      }
      getMenuPriByMenuId(data).then((res) => {
        if (res.data.success) {
          for (let i = 0; i < res.data.result.length; i++) {
            this.menuPriPath.push(res.data.result[i].privilegeAction)
          }
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    getList() {
      this.loading = true
      listPurchase(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.dataList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false
      this.reset()
    },
    handleCustomerView(row) {
      this.purchaseEntity = row
      this.drawerCustomer.visible = true
    },
    // 表单重置
    reset() {
      this.form = {}
      this.$resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加采购信息'
      this.$resetForm('form')
    },
    handleView(row) {
      this.purchaseEntity = row
      this.drawer.visible = true
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.open = true
      this.title = '修改采购信息'
      this.purchaseEntity = row
    },
    editPurchaseResult() {
      this.open = false
      this.getList()
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form)
          if (this.form.guid !== undefined) {
            updatePurchase(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          } else {
            addPurchase(this.form).then((res) => {
              if (res.data.success) {
                this.$msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.$msgError(res.data.message)
              }
            })
          }
        }
      })
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(
        '是否确认删除名称为"' + row.purchaseProductName + '"的数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delPurchase(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.getList()
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
    }
  }
}
</script>
<style scoped lang="scss">
.dialog-footer {
  text-align: center;
}
.el-drawer__header {
  margin-bottom: 10px;
}
.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;
  .kh {
    font-weight: bold;
    color: #6a6969;
  }
  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}
.drawerContent {
  display: flex;
  .left {
    width: 97%;
    margin: 0 0 0 10px;
  }
  .right {
    width: 28%;
    margin: 0 0 0 10px;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);

    .header {
      padding: 0 0 0 10px;
      span {
        line-height: 40px;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
