import { render, staticRenderFns } from "./ocrImg.vue_canvas.vue?vue&type=template&id=71ce0af0&scoped=true"
import script from "./ocrImg.vue_canvas.vue?vue&type=script&lang=js"
export * from "./ocrImg.vue_canvas.vue?vue&type=script&lang=js"
import style0 from "./ocrImg.vue_canvas.vue?vue&type=style&index=0&id=71ce0af0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ce0af0",
  null
  
)

export default component.exports