import { TOKEN_KEY } from '@/utils/constant'
import { post } from '@/plugins/request'
import { Message } from 'element-ui'
import store from '@/store/index'

export const SetToken = async token => {
  
  sessionStorage.removeItem(TOKEN_KEY)
  const result = await post(`/rbac/v0.1/rbac/user/sso/validToken`, { token: token })
  if (result.data.success) {
    sessionStorage.setItem(TOKEN_KEY, result.data.result)
    post('/rbac/v0.1/rbac/user/u/detailByHeaderGuid').then(
      res => {
        store.commit('user/SET_USER', res.data.result)
      },
      err => {
        Message({
          message: err,
          type: 'error',
          duration: 10000
        })
      }
    )
  } else {
    Message({
      message: result.data.message,
      type: 'error',
      duration: 10000
    })
  }
}

export const GetToken = () => {
  // console.log(sessionStorage.getItem(TOKEN_KEY),'token')
  if (!store.state.user.uname && sessionStorage.getItem(TOKEN_KEY)) {
    post('/rbac/v0.1/rbac/user/u/detailByHeaderGuid').then(
      res => {
        debugger
        store.commit('user/SET_USER', res.data.result)
      },
      err => {
        Message({
          message: err,
          type: 'error',
          duration: 10000
        })
      }
    )
  }
  return sessionStorage.getItem(TOKEN_KEY)
}
