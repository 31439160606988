<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col>
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="120px"
        >
          <el-form-item label="任务名称" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入任务名称关键字"
              clearable
              size="small"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="任务分类" prop="key">
            <el-select v-model="queryParams.category" placeholder="请选择">
              <el-option
                v-for="dict in categoryOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              v-preventReClick
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <!-- <el-col :span="1.5">
              <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                v-preventReClick
                @click="handleAdd"
                >新增</el-button
              >
            </el-col> -->
          <right-toolbar
            :showSearch.sync="showSearch"
            @queryTable="getList"
          ></right-toolbar>
        </el-row>

        <el-table
          v-loading="loading"
          :data="taskList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="任务ID"
            align="center"
            key="taskId"
            prop="taskId"
            width="120"
            fixed="left"
          />
          <el-table-column
            label="任务名称"
            align="center"
            key="taskSubject"
            prop="taskSubject"
            :show-overflow-tooltip="true"
            width="360"
            fixed="left"
          />
          <el-table-column
            label="businessKey"
            align="center"
            key="businessKey"
            prop="businessKey"
            width="200"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="任务分类" align="center" prop="category">
            <template slot-scope="scope">
              <span>{{
                $selectDictLabel(categoryOptions, scope.row.category)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="起草人"
            align="center"
            key="startUserId"
            prop="startUserId"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.startUser.userName }}
            </template>
          </el-table-column>
          <el-table-column
            label="起草时间"
            align="center"
            prop="startTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.startTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="任务完成时间"
            align="center"
            prop="endTime"
            width="160"
          >
            <template slot-scope="scope">
              <span>{{ $parseTime(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
            prop="deleReason"
            width="160"
          >
            <template slot-scope="scope">
              <span v-if="getStatus(scope.row) == '01'" style="color: red"
                >已作废</span
              >
              <span v-if="getStatus(scope.row) == '02'" style="color: green"
                >已完成</span
              >
              <span v-if="getStatus(scope.row) == '03'">审批中</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="180"
            class-name="small-padding fixed-width"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-view"
                v-preventReClick
                @click="viewTask(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.currentPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-dialog :visible.sync="openWfUrl" width="70%" append-to-body>
      <iframe :src="taskUrl" frameborder="0" class="iframe"></iframe>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getMyCompletedTaskList } from '@/api/system/activiti'
import config from '@/components/Config'
import { TOKEN_KEY } from '@/utils/constant'
import { ACTIVITI } from '@/utils/activitiConstant'
export default {
  name: 'myCompletedTask',
  data() {
    return {
      openWfUrl: false,
      taskSubject: undefined,
      taskUrl: undefined,
      apiUrl: config.apiUrl, // 后台部署的api服务
      token: sessionStorage.getItem(TOKEN_KEY),
      workFlowTitle: '',
      deployListTitle: '',
      menuPriPath: [],
      modelRow: {},
      wfLoading: false,
      processWfList: [],
      openWf: false,
      lctUrl: '',
      openLct: false,
      keyDisabled: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      showSearchDeploy: true,
      // 总条数
      total: 0,
      deployTotal: 0,
      // 表格数据
      processDefineList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      openEditor: false,
      openDeployList: false,
      openTransferDialog: false,
      deployLoading: false,
      deployModelList: [],
      editorSrc: '',
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      categoryOptions: ACTIVITI.CATEGORY,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        name: undefined,
        key: undefined,
        category: undefined
      },
      transferForm: {},
      taskList: [],
      // 表单参数
      form: {},
      defaultProps: {
        children: 'childrens',
        label: 'name'
      }
    }
  },
  mounted() {},
  created() {
    this.getList()
  },
  methods: {
    getStatus(row) {
      if (row.processInstanceDeleReason) {
        return '01'
      } else if (row.processInstanceEndTime) {
        return '02'
      } else {
        return '03'
      }
    },
    getList() {
      this.loading = true
      getMyCompletedTaskList(JSON.stringify(this.queryParams)).then((res) => {
        if (res.data.success) {
          this.taskList = res.data.result.records
          this.total = res.data.result.total
          this.loading = false
        } else {
          this.$msgError(res.data.message)
          this.loading = false
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.currentPage = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.category = undefined
      this.$resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.roleId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    viewTask(row) {
      this.taskUrl = row.taskUrl
      this.openWfUrl = true
    }
  }
}
</script>
  <style>
.iframe {
  width: 100%;
  height: calc(100vh - 85px);
}
</style>