import Vue from 'vue'
import ElementUI from 'element-ui'
import '@/assets/css/element-variables.scss'
import '@/assets/css/index.scss'
import '@/assets/css/ruoyi.scss'
import Home from './App.vue'
import router from './router'
import request from '@/plugins/request'
import qs from 'qs'
import Config from '@/components/Config.vue'
import i18n from './locale/i18n/i18n'
import  '@/utils/permission.js'
import urlParse  from '@/utils/urlParse'
// 自定义表格工具扩展
import Pagination from '@/components/Pagination/index.vue';
import RightToolbar from '@/components/RightToolBar/index.vue';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css' //样式
 
//创建v-highlight全局指令
Vue.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
    hljs.highlightBlock(block)
  })
})

import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
  parseText
} from '@/utils/common';

Vue.prototype.$urlParse=urlParse
Vue.prototype.$parseTime = parseTime;
Vue.prototype.$resetForm = resetForm;
Vue.prototype.$parseText = parseText;
Vue.prototype.$addDateRange = addDateRange;
Vue.prototype.$selectDictLabel = selectDictLabel;
Vue.prototype.$selectDictLabels = selectDictLabels;
Vue.prototype.$handleTree = handleTree;

Vue.prototype.$request = request
Vue.prototype.$qs = qs
Vue.prototype.$Config = Config
Vue.prototype.i18n = i18n


Vue.prototype.$msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' });
};

Vue.prototype.$msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'error' });
};

Vue.prototype.$msgInfo = function (msg) {
  this.$message.info(msg);
};

Vue.use(ElementUI)
//全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('RightToolbar', RightToolbar);

// 防重复点击(指令实现)
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      // debugger
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  }
});
new Vue({
  router,
  i18n,
  render: h => h(Home)
}).$mount('#app')
