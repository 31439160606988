<template>
  <div>
    <div class="drawerTitle">
      <div class="kh">
        <span
          ><i class="el-icon-potato-strips"></i>
          {{ customer.customerName }}</span
        >
      </div>
      <div class="sub">
        <span>负责人：{{ customer.creatorName }}</span>
        <span>创建时间：{{ $parseTime(customer.created) }}</span>
        <span>状态：{{ customer.customerStatusName }}</span>
      </div>
    </div>
    <el-divider><i class="el-icon-arrow-down"></i></el-divider>
    <div class="drawerContent">
      <div
        class="left"
        :style="{ height: windowHeight + 'px', 'overflow-y': 'auto' }"
      >
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-date"></i> 基本信息</span>
            <customerInfo
              v-model="customer.guid"
              @editCustomer="editCustomer"
            />
          </el-tab-pane>
          <el-tab-pane label="相关商机">
            <customerOpportunity v-model="customer.guid"></customerOpportunity>
          </el-tab-pane>
          <el-tab-pane label="采购记录">
            <customerPurchase v-model="customer.guid"></customerPurchase>
          </el-tab-pane>
          <el-tab-pane label="联系人">
            <customerContact v-model="customer.guid"></customerContact>
          </el-tab-pane>
          <el-tab-pane label="待办记录">
            <customerTasks v-model="customer.guid"></customerTasks>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        class="right"
        :style="{ height: windowHeight + 'px', overflow: 'hidden' }"
      >
        <customerFollow v-model="customer.guid"></customerFollow>
      </div>
    </div>
  </div>
</template>
<script>
import customerInfo from '@/views/system/crm/common/customerInfo.vue'
import customerContact from '@/views/system/crm/common/customerContact.vue'
import customerOpportunity from '@/views/system/crm/common/customerOpportunities.vue'
import customerPurchase from '@/views/system/crm/common/customerPurchase.vue'
import customerFollow from '@/views/system/crm/common/customerFollow.vue'
import customerTasks from '@/views/system/crm/common/customerTasks.vue'
export default {
  name: 'ViewCustomer',
  components: {
    customerInfo,
    customerContact,
    customerOpportunity,
    customerPurchase,
    customerFollow,
    customerTasks
  },
  data() {
    return {
      windowHeight: 0,
      customer: this.value,
      myValue: this.value
    }
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  created() {
    this.initWindowHeight()
  },
  mounted() {},
  methods: {
    initWindowHeight() {
      let pageHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      this.windowHeight = pageHeight - 140
      console.log(this.windowHeight)
    },
    //编辑
    editCustomer(customerV) {
      this.customer = customerV
    }
  },
  watch: {
    value(newValue) {
      this.customerInfo = newValue
    }
  }
}
</script>
<style scoped lang="scss">
.drawerTitle {
  margin: 15px 0 0 10px;

  line-height: 30px;

  .kh {
    font-weight: bold;
    color: #6a6969;
  }

  .sub {
    span {
      margin: 0 20px 0 0;
      font-size: 14px;
      color: #72767b;
    }
  }
}
.drawerContent {
  display: flex;

  .left {
    width: 69%;
    margin: 0 0 0 10px;
  }

  .right {
    width: 28%;
    margin: 0 0 0 10px;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    padding: 8px;
    .header {
      padding: 0 0 0 10px;

      span {
        line-height: 40px;
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
