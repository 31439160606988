import { getMenuTreeByPid } from '@/api/menu'
const permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: []
  },
  mutations: {},
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        let data={
          parentId: ''
        }
        getMenuTreeByPid(data).then(res => {
          // debugger
          const rdata = JSON.parse(JSON.stringify(res.data.result))
          const rewriteRoutes = filterAsyncRouter(rdata)
          resolve(rewriteRoutes)
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(route => {
    // debugger
    route.path = route.routPath
    route.name = route.routPath
    route.hidden = false
    // route.redirect = "noRedirect";
    route.meta = {
      title: route.menuName,
      icon: ''
    }
    if (route.componentPath) {
      // Layout ParentView 组件特殊处理
      if (route.componentPath === 'Layout') {
        route.component = resolve => require(['@/components/Home.vue'], resolve)
      } else {
        try {
          route.component = resolve =>
            require([`@/views/${route.componentPath}`], resolve)
        } catch (err) {
          console.log(err, ' error!')
          route.component = resolve =>
            require(['@/components/ParentView/index.vue'], resolve)
        }
      }
    }
    if (route.childrens != null && route.childrens && route.childrens.length) {
      route.children = filterAsyncRouter(route.childrens)
    }
    return true
  })
}

export default permission
