<template>
  <div class="ft">
    <div class="an"><el-button size="mini" @click="addFollowRecords">写跟进记录</el-button>
        <!-- <el-button size="mini">写待办</el-button> -->
    </div>
    <div class="list">
    <div class="task">

    </div>
    <el-divider><span class="dividerSpan">跟进记录</span></el-divider>
    <div class="follow" :style="{'height':(windowHeight-100)+'px','overflow-y': 'auto'}">
        <el-timeline v-loading="followLoading" :infinite-scroll-disabled="disabledScrollFollow" v-infinite-scroll="loadFollowScroll">
            <el-timeline-item 
            :timestamp="$parseTime(item.created,'{y}-{m}-{d}')" 
            placement="top" 
            v-for="item in followDataList" 
            :key="item.guid"
            >
                <el-card class="el-card_n" body-style="padding:0 0 0 0;">
                  <p style="padding: 0 0 0 10px;">跟进人员：{{ item.creatorName }} <i @click="editFollow(item)" class="el-icon-edit"></i><i @click="deleteFollow(item)" class="el-icon-delete"></i></p> 
                  <p style="padding: 0 0 0 10px;">跟进方式：{{ item.followCategoryName }}</p>  
                  <p style="padding: 0 0 0 10px;">跟进内容：</p>
                  <h4 v-html="$parseText(item.followContent)"></h4>
                  <p style="padding: 0 0 0 10px;">跟进时间：{{ $parseTime(item.created) }}</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
        <p v-if="loadingFollow" style="text-align: center;">加载中...</p>
        <p v-if="noMoreFollow" style="text-align: center;">没有更多了</p> 
    </div>
    </div>
    <el-dialog :title="followTitle" :visible.sync="followVisible" width="800px"  append-to-body v-if="followVisible">
        <editFollow :customer_guid="myValue" v-model="followEntity.guid" @editResult="editResult"></editFollow>
    </el-dialog>
  </div>
</template>
<script>
import {getFollow,listFollow,addFollow,updateFollow,delFollow} from '@/api/crm/follow'
import editFollow from '@/views/system/crm/common/editFollow.vue'
export default {
    name:'customerFollow',
    components:{
        editFollow
    },
    data(){
        return {
           windowHeight:0,
           disabledScrollFollow:false,
           myValue:this.value,
           followVisible:false,//跟进记录是否显示
           followTitle:'',//跟进记录弹窗标题
           followEntity:{},//对象实体
           followLoading:false,
           followDataList:[],
           followTotal:0,
           loadingFollow:false,
           noMoreFollow:false,
           // 查询参数
            queryFollowParams: {
                currentPage: 1,
                pageSize: 5,
                customerGuid: this.value
            },
        }
    },
    props:{
        value: {
            type: String,
            default: ''
        }
    },
    created(){
        this.initWindowHeight();
    },
    mounted(){

    },
    methods:{
        initWindowHeight(){
            let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            this.windowHeight=pageHeight-140;
            console.log(this.windowHeight)
        },
        // editFollow 回调函数
        editResult(v){
            this.followVisible = false;
            this.queryFollowParams.currentPage=1;
            this.followDataList=[];
            this.loadFollowScroll();
        },
        // 添加跟随记录点击事件
        addFollowRecords(){
            
            this.followVisible=true;
            this.followTitle='添加跟进记录';
        },
        // 编辑跟随记录
        editFollow(row){
           this.followEntity=row;
           this.followVisible=true;
           this.noMoreFollow=false;
           this.disabledScrollFollow=false;
           this.followTitle="修改跟进记录";
        },
        // 删除跟随记录
        deleteFollow(row){
            this.$confirm(
        '是否确认删除数据项?',
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(function () {
          let data = {
            guid: row.guid
          }
          return delFollow(data)
        })
        .then((res) => {
          if (res.data.success) {
            this.queryFollowParams.currentPage=1;
            this.followDataList=[];
            this.noMoreFollow=false;
            this.disabledScrollFollow=false;
            this.loadFollowScroll();
            this.$msgSuccess('删除成功')
          } else {
            this.$msgError(res.data.message)
          }
        })
        },
        // 获取跟随记录列表
        loadFollowScroll(){
            if(this.loadingFollow)return;
            this.loadingFollow=true;
            listFollow(JSON.stringify(this.queryFollowParams)).then((res) => {
                if (res.data.success) {
                   if(res.data.result.records.length>0){
                        res.data.result.records.forEach(item=>{
                           this.followDataList.push(item);
                        });
                        console.log(this.followDataList)
                        this.loadingFollow=false;
                        this.loadingFollow=false;
                        this.queryFollowParams.currentPage+=1;
                   }else{
                    this.disabledScrollFollow=true;
                    this.loadingFollow=false;
                        this.noMoreFollow=true;
                   }
                   
                } else {
                this.$msgError(res.data.message)
                }
            })
        }

    },
    watch:{
        value(newValue){
            this.myValue=newValue;
            this.queryFollowParams.customerGuid=newValue;
        }
    }
}
</script>

<style lang="scss" scoped>
.el-card_n{
   padding: 0 0 0 0;
}
.ft{
    .follow{
        margin:10px 0 0 0;
        p{
            font-size: 14px;
           color: #72767b;
           line-height: 12px;
        }
        h4{
            background-color: #f7f7f7;
            margin: 10px;
            padding: 10px;
        }
        .el-icon-delete{
            position: absolute;
            right: 10px;
        }
        .el-icon-edit{
            position: absolute;
            right: 30px;
        }
    }
    .dividerSpan{
        font-size: 12px;
        color:#72767b;
    }
    ul,li{
        list-style-type:none;
        margin:0 0 0 0;
        padding:0 0 0 0;
    }
}

</style>