import {post} from '@/plugins/request';

// 新增
export function addUser(data) {
    return post('/rbac/v0.1/rbac/user/u/create',data);
  }
  
  // 修改
  export function updateUser(data) {
    return post('/rbac/v0.1/rbac/user/u/update',data);
  }
  
  // 删除
  export function delUser(data) {
    return post('/rbac/v0.1/rbac/user/u/delete',data);
  }

  // 详情
  export function getUser(data) {
    return post('/rbac/v0.1/rbac/user/u/detail',data);
  }
  // 列表
  export async function listUser(data) {
    return post('/rbac/v0.1/rbac/user/u/list',data);
  }
  export async function listAllUser(data) {
    return post('/rbac/v0.1/rbac/user/u/listAdm',data);
  }
  //根据角色查询用户
  export function listUserByRoleGuid(data){
    return post('/rbac/v0.1/rbac/user/ur/list',data);
  }
 // 根据用户查询角色
  export function listRoleByUserGuid(data) {
    return post('/rbac/v0.1/rbac/user/ur/listRoleByUserGuid',data);
  }
  export function createUserRoleByList(data){
    return post('/rbac/v0.1/rbac/user/ur/batch/create',data);
  }
  //批量保存用户角色匹配关系
  export function addUserRole(data) {
    return post('/rbac/v0.1/rbac/user/ur/batch/create',data);
  }
 