<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip
        class="item"
        effect="dark"
        :content="showSearch ? '隐藏搜索' : '显示搜索'"
        placement="top"
      >
        <el-button
          size="mini"
          circle
          icon="el-icon-search"
          @click="toggleSearch()"
          v-preventReClick
        />
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="刷新" placement="top">
        <el-button
          size="mini"
          circle
          icon="el-icon-refresh"
          @click="refresh()"
          v-preventReClick
        />
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="显隐列"
        placement="top"
        v-if="columns"
      >
        <el-button
          size="mini"
          circle
          icon="el-icon-menu"
          @click="showColumn()"
          v-preventReClick
        />
      </el-tooltip>
    </el-row>
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-transfer
        :titles="['显示', '隐藏']"
        v-model="value"
        :data="columns"
        @change="dataChange"
      ></el-transfer>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'RightToolbar',
    data() {
      return {
        // 显隐数据
        value: [],
        // 弹出层标题
        title: '显示/隐藏',
        // 是否显示弹出层
        open: false
      };
    },
    props: {
      showSearch: {
        type: Boolean,
        default: true
      },
      columns: {
        type: Array
      }
    },

    methods: {
      // 搜索
      toggleSearch() {
        this.$emit('update:showSearch', !this.showSearch);
      },
      // 刷新
      refresh() {
        this.$emit('queryTable');
      },
      // 右侧列表元素变化
      dataChange(data) {
        for (var item in this.columns) {
          const key = this.columns[item].key;
          this.columns[item].visible = !data.includes(key);
        }
      },
      // 打开显隐列dialog
      showColumn() {
        this.open = true;
      }
    }
  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-transfer__button {
    border-radius: 50%;
    padding: 12px;
    display: block;
    margin-left: 0px;
  }
  ::v-deep .el-transfer__button:first-child {
    margin-bottom: 10px;
  }
</style>
